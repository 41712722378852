export enum ETLCodes {
  AAPS1,
  AAPS2,
  ABS1,
  ABS2,
  ACES1,
  ACES2,
  ADeliberee,
  ADeliberer,
  AIS1,
  AIS2,
  AaS1,
  AaS2,
  Absences,
  AbsencesOEVA,
  Acceptation,
  Accepte,
  Acces,
  Accord,
  AccordCentre,
  Acquis,
  AcquisProfessionnel,
  AcquisScolaire,
  Actif,
  ActifComputed,
  ActionCode_ABSENCE,
  ActionCode_ABSENCES,
  ActionCode_ACQUIS,
  ActionCode_ACQUISPROF,
  ActionCode_ACQUISPROFESSIONNEL,
  ActionCode_ACTIVITESPROFESSIONELLES,
  ActionCode_ACTIVITESPROFESSIONNELLES,
  ActionCode_ADMINISTRATIF,
  ActionCode_AGREMENT,
  ActionCode_AGREMENTCLASSE,
  ActionCode_AGREMENTLOCALISATION,
  ActionCode_AGREMENTS,
  ActionCode_ANNEEREUSSIE,
  ActionCode_ANNEESCOLAIRE,
  ActionCode_APPRENANT,
  ActionCode_APPRENANTS,
  ActionCode_BEIDREADER,
  ActionCode_CALENDRIER,
  ActionCode_CENTREGLOBAL,
  ActionCode_CENTRES,
  ActionCode_CLASSE,
  ActionCode_CLASSELIEE,
  ActionCode_CLASSES,
  ActionCode_CLASSESLIEE,
  ActionCode_CLASSESLIEES,
  ActionCode_CLAUSE,
  ActionCode_CLAUSESLIEES,
  ActionCode_CODEPOSTAL,
  ActionCode_COHERENCESHORAIRE,
  ActionCode_CONSEILLERPEDAGOGIQUE,
  ActionCode_CONSEILLERSLIES,
  ActionCode_CONTRATFORMATEUR,
  ActionCode_COPIE,
  ActionCode_DEGRE,
  ActionCode_DELEGUETUTELLE,
  ActionCode_DELIBERATIONMASSE,
  ActionCode_DETAIL,
  ActionCode_DIPLOME,
  ActionCode_DIRECTIONTERRITORIALE,
  ActionCode_DOUBLON,
  ActionCode_ECOLE,
  ActionCode_EVALUATION,
  ActionCode_EXAMEN,
  ActionCode_EXAMENPRATIQUE,
  ActionCode_EXPORTATION,
  ActionCode_FEUILLEPRESENCE,
  ActionCode_FINANCE,
  ActionCode_FORMATEUR,
  ActionCode_FORMATEURLIE,
  ActionCode_FORMATEURSLIE,
  ActionCode_FORMATEURSLIES,
  ActionCode_FORMATION,
  ActionCode_FORMATIONS,
  ActionCode_GENERAL,
  ActionCode_GESTIONDROITS,
  ActionCode_HORAIRE,
  ActionCode_HORAIRES,
  ActionCode_IMPRESSION,
  ActionCode_INFORMATION,
  ActionCode_INFORMATIONS,
  ActionCode_INSCRIPTION,
  ActionCode_INSCRIPTIONMASSE,
  ActionCode_INSCRIPTIONS,
  ActionCode_JURE,
  ActionCode_JURES,
  ActionCode_JUSTIFICATIF,
  ActionCode_JUSTIFICATIFS,
  ActionCode_LIEUXCOURS,
  ActionCode_LIEUFORMATION,
  ActionCode_LISTEAPPRENANTS,
  ActionCode_LOCAL,
  ActionCode_LOCALISATION,
  ActionCode_LOCALISATIONCLASSE,
  ActionCode_LOCALITES,
  ActionCode_MASSEMISEAJOURCLASSE,
  ActionCode_MATIERE,
  ActionCode_MATIERES,
  ActionCode_METIER,
  ActionCode_METIERLIES,
  ActionCode_METIERSLIES,
  ActionCode_PAYS,
  ActionCode_PERMISSION,
  ActionCode_PERMISSIONS,
  ActionCode_PREFERENCESHORAIRE,
  ActionCode_PREFERENCESHORAIRES,
  ActionCode_PRESTATIONS,
  ActionCode_PROFESSIONFORMATEUR,
  ActionCode_PROJETFINANCEMENT,
  ActionCode_QUOTA,
  ActionCode_RECHERCHEPATRON,
  ActionCode_REFERENTIEL,
  ActionCode_REMPLACEMENT,
  ActionCode_REMPLACEMENTS,
  ActionCode_REPRESENTANT,
  ActionCode_REPRESENTANTS,
  ActionCode_RESPONSABLE,
  ActionCode_RESULTATTYPE,
  ActionCode_SEARCH,
  ActionCode_SECTEURCLASSEHOMOGENE,
  ActionCode_SECTEURMETIER,
  ActionCode_SERVICETUTELLE,
  ActionCode_SIGNALETIQUE,
  ActionCode_SMS,
  ActionCode_SPECIFICITECOURS,
  ActionCode_STADE,
  ActionCode_STATUTSOCIAL,
  ActionCode_SUIVI,
  ActionCode_SUIVIAPPRENANT,
  ActionCode_TITREPEDAGOGIQUE,
  ActionCode_TYPECOURS,
  ActionCode_TYPEDELIBERATION,
  ActionCode_TYPEENSEIGNEMENT,
  ActionCode_TYPEEVALUATION,
  ActionCode_TYPEFINANCEMENT,
  ActionCode_USERS,
  ActionCode_VALIDATIONCE,
  ActionCode_VALIDATIONMASSE,
  ActionCode_VISITEEXTERIEURE,
  ActionCode_ENVELOPPE,
  Actions,
  Activite,
  ActivitesProfessionelles,
  AddAll,
  AddAllText,
  AddConfirmMessage,
  AddElementText,
  AddSection,
  AddedToMessageAutomatically,
  Adjoints,
  Administratif,
  Adresse,
  AdresseAutre,
  AdresseCourrier,
  AdresseDomicile,
  AdressePrincipale,
  AdresseSecondaire,
  Agree,
  Agrement,
  AgrementClasses,
  AgrementLocalisation,
  Agrements,
  AgrementsClasses,
  AjoutDateHoraire,
  AllReports,
  AncienMatricule,
  Annee,
  AnneeExamen,
  AnneeReussie,
  AnneeScolaire,
  AnneeScolaires,
  App,
  AppliquerSurTous,
  Apprenant,
  ApprenantFinal,
  Apprenants,
  AreYouSureToDelete,
  AttestationFOREM,
  Au,
  Aucun,
  AucuneUtilisateurTrouvé,
  Autre,
  AutreACharge,
  AutreHandicape,
  Avis,
  AvisRetrait,
  BAPS1,
  BAPS2,
  BCES1,
  BCES2,
  BackendError_CannotDeleteAgrement,
  BackendError_CannotModifyChangeClasse,
  BackendError_ClasseMatiereLinkedToClasseHoraire,
  BackendError_ClasseMatiereLinkedToCourse,
  BackendError_ClasseMatiereLinkedToDispense,
  BackendError_ClasseMatiereLinkedToHoraire,
  BackendError_ClasseResultatLocked,
  BackendError_ConstraintViolation,
  BackendError_DateCannotBeInferior,
  BackendError_DbEntityReferenceException,
  BackendError_DuplicateExist,
  BackendError_EntityExists,
  BackendError_EntityDoesntExist,
  BackendError_FormateurLinked,
  BackendError_FormationLinkedToInscription,
  BackendError_LoginAlreadyExists,
  BackendError_NoValidatedLocalisation,
  BackendError_SmsSendError,
  BackendError_ClasseDoesNotExist,
  BackendError_MetierNotCompatibleWithDegre,
  BackendError_ClasseLinkedToInscription,
  BackendError_ClasseLinkedToEvaluation,
  BackendError_ClasseLinkedToPresence,
  BackendError_DateCoursNotInAnyAnneeScolaire,
  BaseDeDonnees,
  BeidReader,
  BicPlaceHolder,
  Boite,
  Bulletin,
  BulletinSess1,
  BulletinSess2,
  C8,
  CE,
  CEAS1,
  CEAS2,
  CEBS1,
  CEBS2,
  CEIS1,
  CEIS2,
  CalculCoherence,
  CalculCoherenceSuccess,
  CalculQuota,
  Calendrier,
  Calendriers,
  CandidatAlternance,
  CannotDeleteMatiereWithHoraires,
  Caracteristique,
  CardNumber,
  CarteIdentite,
  CarteIdentiteRemise,
  Categorie,
  Central,
  Centre,
  CentreDemandeur,
  CentreDetailPage_CodeExistsError,
  CentreFormation,
  CentreGlobal,
  CentreGlobalListPageEdit,
  CentreOuDelegueRequis,
  CentreSearchCriterias_Actif,
  CentreSearchCriterias_Code,
  CentreSearchCriterias_Directeur,
  CentreSearchCriterias_Nom,
  Centres,
  CentresLies,
  ChampDouble,
  ChangeCentre,
  ChangePassword,
  ChangementClasse,
  ChoisirCetApprenant,
  Classe,
  ClasseCible,
  ClasseHoraireCoherence,
  ClasseIdentifiant,
  ClasseLiee,
  ClasseMatiere,
  ClasseMatiereSaveContratFormateurIncompatible,
  ClasseMatiereSaveFormateurNotAgrement,
  ClassePrincipale,
  ClasseSource,
  Classes,
  ClassesMiseAJour,
  ClauseListPageClause,
  ClauseListPageStade,
  ClauseListPageTitle,
  ClausesLiees,
  ClausesParticulieres,
  Code,
  CodeBIC,
  CodeClasse,
  CodeConseillerAgrement,
  CodeExistsError,
  CodeFormation,
  CodeMatiere,
  CodePays,
  CodePostal,
  CodePostalListPageError,
  CodeSecteur,
  Codesecteur,
  Codeclasse,
  Codereferentiel,
  CodesPostaux,
  CoherenceHoraire,
  CoherenceHoraireSemestre,
  CoherenceHoraires,
  Coherent,
  CoherentHoraire,
  CommentaireDeliberation,
  Commune,
  CommuneNaissance,
  ComputedActif,
  ConfirmPassword,
  Conge,
  ConjointCharge,
  Connecte,
  ConnectedToBEID,
  Cons,
  Conseiller,
  ConseillerPedagogique,
  ConseillerPedagogiqueCode,
  ConseillersSearchCriterias_Actif,
  ConseillersSearchCriterias_IdconseillerPedagogique,
  ConseillersSearchCriterias_Nom,
  ConseillersSearchCriterias_Prenom,
  ConseillersLies,
  ConseillersPedagogiques,
  Conserver,
  ConsulterFiche,
  ConsulterHoraire,
  Contact,
  ContextMenuChooseColumns,
  Contrat,
  ContratFormateur,
  ContratsFormateurs,
  Coordonnee,
  CopieClasse,
  CopierContratFormateur,
  Cote,
  CoteMax,
  CoteMaximale,
  CoteRef,
  Cotes,
  Cours,
  CoursDu,
  CourseType,
  CoutGlobal,
  CoutInitial,
  CoutMatiere,
  CoutSms,
  CoutTotal,
  CreatedBy,
  CreatedOn,
  CreationDate,
  CreerApprenant,
  CreerFormateur,
  CreerRepresentant,
  Cut,
  DFC,
  Date,
  DateAbandon,
  DateAbsence,
  DateAcceptation,
  DateAgrement,
  DateAjouter,
  DateChangement,
  DateConvocation,
  DateCours,
  DateCreation,
  DateCreationClasse,
  DateDeNaissanceNeCorrespondPas,
  DateDebut,
  DateDebutSearch,
  DateDeces,
  DateDeliberation,
  DateDemande,
  DateDemandeNotValid,
  DateDispense,
  DateEncodage,
  DateEntreeCentre,
  DateEntreeIfapme,
  DateEnvoi,
  DateExamen,
  DateFOREM,
  DateFermeture,
  DateFin,
  DateFinTooLowError,
  DateInscription,
  DateLocalisation,
  DateNaissance,
  DateNaissanceDoublon,
  DatePaiement,
  DatePrevue,
  DateRealisation,
  DateSuivi,
  DateValidation,
  DateVisiteDebut,
  DateVisiteFin,
  Db,
  Debut,
  DebutActivite,
  DebutContrat,
  Decision,
  Degre,
  Degres,
  Delegue,
  DeleguesTutelle,
  DeleteConfirmationMessage,
  DeleteDialogTitle,
  DeleteHoraire,
  DeleteSuccess,
  DeletedErrorMessage,
  Deliberation,
  Deliberation2sess,
  DeliberationActee,
  DeliberationMasse,
  DeliberationSecondeSessions,
  DeliberationSuggeree,
  DeliberationType,
  DeliberationsMasse,
  DeliberationsType,
  Demande,
  DernierDiplome,
  DernierDiplomeObtenu,
  DerniereAnneeReussie,
  DernieresAnneesReussies,
  Derogation,
  Description,
  Desiderata,
  Detail,
  DetailVueEdition,
  Details,
  DeuxiemeSession,
  Difference,
  DimShortName,
  Dimanche,
  Diplome,
  DiplomeRemis,
  Diplomes,
  Directeur,
  DirectionsTerritoriales,
  DispenseMatiere,
  DispensesMatieres,
  DispensesOrChangementsExists,
  DocumentC2,
  Donnees,
  DoublonExistsConfirm,
  Doublons,
  DoublonsNatNumberExitsConfirm,
  Duree,
  Ecole,
  EcoleSearchCriterias_Actif,
  EcoleSearchCriterias_Adresse,
  EcoleSearchCriterias_CodePays,
  EcoleSearchCriterias_CodePostal,
  EcoleSearchCriterias_Nom,
  Ecoles,
  Edition,
  EleveLibre,
  Email,
  EmailPro,
  EncodagePar,
  EncoderRetard,
  EnfantACharge,
  EnfantHandicape,
  EnseignementSuivi,
  Entreprise,
  EnveloppeProjetFinancement,
  EnveloppeTypeFinancement,
  EnvoiACetteAdresse,
  EnvoiCourrier,
  EnvoyeLe,
  ErreurCalculIncoherence,
  Error,
  ErrorAnneeScolaireAlreadyExists,
  ErrorCodeAlreadyExistsForClasse,
  ErrorFormateurAgrementMetier,
  ErrorFormateurDepassementContrat,
  ErrorPhoneRegex,
  ErrorWhileExporting,
  Error_BackendNotRecheable,
  Error_Login,
  Etat,
  EtatAgrement,
  EtatCivil,
  EtatContrat,
  EtatLocalisation,
  Europe,
  Evaluation,
  Evaluations,
  Examen,
  ExamenC,
  ExamenEntree,
  ExamenPratique,
  ExamensEntree,
  Executer,
  Executeur,
  ExportExcel,
  Exportations,
  ExportationsGAP,
  FAGS1,
  FAGS2,
  FCO,
  FGSelectInput_Filter,
  FGSelectInput_Loading,
  FGSelectInput_NoOptions,
  FGSelectInput_SelectAnItem,
  FGMultiSelectInput_SelectAll,
  FO,
  Fax,
  FaxApp,
  FaxAutre,
  FaxCE,
  FaxFCO,
  FaxPrive,
  FaxPro,
  Feminin,
  FermetureClasse,
  FeuillesPresence,
  Filter,
  Fin,
  FinActivite,
  FinContrat,
  Finale,
  Finance,
  Financement,
  FirstNames,
  FixMenuTooltip,
  ForfaitAuditeur,
  FormDirtyRouteChangeMessage,
  Formateur,
  FormateurHandicape,
  FormateurLie,
  Formateurs,
  Formation,
  Formations,
  FraisDossier,
  FraisPOE,
  Frontalier,
  Fusion,
  FusionSuccess,
  General,
  GeneralInformation,
  GeneralLoading,
  GeneralNoData,
  General_Add,
  General_Cancel,
  General_Copy,
  General_Delete,
  General_Download,
  General_Edit,
  General_Email,
  General_GoBack,
  General_No,
  General_Password,
  General_Save,
  General_SaveErrorMessage,
  General_SavedSuccessMessage,
  General_TableCriterias,
  General_UserName,
  General_View,
  General_Yes,
  GenerePlan,
  Genre,
  GenreNeCorrespondPas,
  Gestion,
  GestionConges,
  GestionDroits,
  GestionInterne,
  GestionInterneAgrement,
  Global,
  Grade,
  GrandTotal,
  Grille,
  GrilleCe,
  Groupement,
  Gsm,
  GsmPro,
  HReelleS1,
  HReelleS2,
  HeureHoraire,
  HeureNeutralise,
  HeureReferentiel,
  HeureRemplacee,
  HeureS1,
  HeureS2,
  Heures,
  HeuresAbsences,
  HeuresJustifiees,
  HeuresPresences,
  HeuresPrestees,
  Homonyme,
  HomonymeSucess,
  Horaire,
  HoraireCoherent,
  HoraireModifieLe,
  HoraireModifiePar,
  Horaires,
  IAPS1,
  IAPS2,
  ICES1,
  IDContrat,
  IECS2,
  IdDegre,
  IdGenre,
  IdUser,
  IdanneeScolaire,
  Idapprenant,
  Idcentre,
  Idclasse,
  IdconseillerAgrement,
  IdconseillerPedagogique,
  Identifiant,
  Identite,
  Idevaluation,
  IdfinancementType,
  Idlieu,
  Idmetier,
  Idmode,
  Idstade,
  IdstatutStage,
  IdtypeCours,
  Impressions,
  Incoherence,
  Independant,
  Indice,
  IndiceRecyclageS1,
  IndiceRecyclageS2,
  Informations,
  Inscription,
  InscriptionMasse_AnneeScolaire,
  InscriptionMasse_Classe,
  InscriptionMasse_CodeMetier,
  InscriptionMasse_Degre,
  InscriptionMasse_Deliberation,
  InscriptionMasse_Deliberation2sess,
  InscriptionMasse_Formation,
  InscriptionMasse_Idclasse,
  InscriptionMasse_Nom,
  InscriptionMasse_Prenom,
  Inscriptions,
  InscriptionsMasse,
  InscriptionsMasseLocalisationClasses,
  Institutions,
  Integre,
  Interne,
  InvalidEmail,
  InvalidIban,
  IsTest,
  JDTCR,
  JeuShortName,
  Jeudi,
  Jour,
  JourAjoute,
  JourClasse,
  Journee,
  Jours,
  Jure,
  Jures,
  Justificatifs,
  KitSurvie,
  Km,
  Langue,
  Lecture,
  Lecture_Ecriture,
  Lecture_Ecriture_Suppression,
  Legitime,
  Legitimite,
  Levels,
  Libelle,
  LibelleClasse,
  LibelleExistsError,
  LienParente,
  Lieu,
  LieuFormation,
  LieuNaissance,
  Lieucours,
  LieuxDeCours,
  ListeDesApprenants,
  ListeInscriptions,
  ListeLiee,
  Local,
  LocalErrorWhileSaving,
  Localisation,
  LocalisationsClasses,
  Localise,
  Localite,
  LocaliteCentre,
  LocaliteLiee,
  LocaliteNaissance,
  Localites,
  LocationAndBirthDate,
  Locaux,
  Login,
  LoginBtn,
  LoginInput,
  Login_PasswordIsRequired,
  Login_UserNameIsRequired,
  Logout,
  LunShortName,
  Lundi,
  MarShortName,
  Mardi,
  Masculin,
  MatchGsmCP,
  MatchTelCP,
  Matiere,
  MatiereAccordee,
  MatiereDemandee,
  Matieres,
  Matricule,
  MaxCharacterApprenantNom,
  MaxCharacterApprenantPrenom,
  MaxCharacterLocalite,
  MaxCharacterNom,
  MaxCharacterPrenom,
  MaxCharacterTitle,
  MaxCharactercodePostal,
  MaxHForm,
  MaxHJury,
  MaxNumberPrecompte,
  Menu,
  MerShortName,
  MerciDeNePasRepondre,
  Mercredi,
  Message,
  Metier,
  MetierDetailPage_StadeIndiceExistsError,
  MetierDetailpage_CommissionParitaire,
  MetierDetailpage_DegreFinal,
  MetierDetailpage_DegreFinalAdditionnel,
  MetierDetailpage_Feminin,
  MetierDetailpage_Indice,
  MetierDetailpage_Integre,
  MetierDetailpage_Masculin,
  MetierDetailpage_PratiqueSimulee,
  MetierDetailpage_Remarque,
  MetierDetailpage_Stade,
  MetierListPageConseiller,
  MetierListPageIndice,
  MetierListPageIntegre,
  MetierListPageMetier,
  MetierListPageStade,
  MetierSearchCriterias_Code,
  MetierSearchCriterias_Conseiller,
  MetierSearchCriterias_Idstade,
  MetierSearchCriterias_Integre,
  MetierSearchCriterias_Masculin,
  MetierSearchCriterias_Actif,
  Metiers,
  MetiersLies,
  MetiersLiesGestionGenerique,
  MinHForm,
  Minerval,
  Minutes,
  MiseAJour,
  MiseAJourFiche,
  Mode,
  ModifiedBy,
  ModifiedOn,
  ModifierPhoto,
  Modify,
  Module,
  Module_Tab,
  Modules,
  ModulesExportation,
  ModulesImpression,
  Mois,
  Montant,
  Nationalite,
  Nb,
  NbApprenantLocalisation,
  NbCandidat,
  NbHeure,
  NbHeureJury,
  NbHeureMax,
  NbHeureMaximum,
  NbHeureMin,
  NbHeureMinimum,
  NbHeureReelle,
  NbHeuresCours,
  NbHoursNotCorresponding,
  NbRemplacementNonPayes,
  NbRemplacementPayes,
  NbRemplacements,
  NbSceances,
  NbapprenantAgrement,
  NbrDestinataire,
  NbrEnvoye,
  NbrErreur,
  New,
  NewCentreGlobal,
  NiveauAcces,
  NoContracts,
  NoValue,
  Nom,
  NomClasse,
  NomConseiller,
  NomDelegue,
  NomDoublon,
  NomMatiere,
  NomNouvelleClasse,
  NomPatron,
  NomSa,
  NombreApprenantCalcule,
  NombreApprenantPrevu,
  NombreApprenantReel,
  NombreInscrit,
  NonVacataire,
  NotAuthorized,
  NotConnectedToBEIDClikToDownload,
  NotValid,
  NumCompte,
  NumberTooHigh_100,
  Numero,
  NumeroBanque,
  NumeroCI,
  NumeroIdentification,
  NumeroNational,
  Orderjour,
  Ordre,
  OrganisationDesCours,
  PPB,
  Pager_Selector_Items,
  Pager_Selector_Items_per_page,
  Pager_Selector_Of,
  Pager_Selector_Page,
  Paiements,
  PasswordMustMatch,
  Patron,
  Pays,
  PaysDetailPage_DescriptionExistsError,
  PaysExists,
  PaysFormCodeLimit,
  PaysFormPaysLimit,
  PaysListPageCodePays,
  PaysListPageEdit,
  PaysListPageEurope,
  PaysListPageFeminin,
  PaysListPageMasculin,
  PaysListPagePays,
  PaysNaissance,
  PaysSaveSuccess,
  PeriodeInactivite,
  Permissions,
  PersonnelTutelle,
  PersonnelTutelleItemPageCode,
  PersonnelTutelleItemPageEmail,
  PersonnelTutelleItemPageGenre,
  PersonnelTutelleItemPageGsm,
  PersonnelTutelleItemPageIndependant,
  PersonnelTutelleItemPageNewDelegue,
  PersonnelTutelleItemPageNom,
  PersonnelTutelleItemPageNumRegNat,
  PersonnelTutelleItemPagePrenom,
  PersonnelTutelleItemPageSaveSuccess,
  PersonnelTutelleItemPageServiceTutelle,
  PersonnelTutelleListEmail,
  PersonnelTutelleListIndependant,
  PersonnelTutelleListPageCode,
  PersonnelTutelleListPageEdit,
  PersonnelTutelleListPageGsm,
  PersonnelTutelleListPageNom,
  PersonnelTutelleListPagePrenom,
  Plage,
  Plage1,
  Plage10,
  Plage11,
  Plage12,
  Plage13,
  Plage14,
  Plage2,
  Plage3,
  Plage4,
  Plage5,
  Plage6,
  Plage7,
  Plage8,
  Plage9,
  Plages,
  PlanAcces,
  PlanLocalisation,
  Planifie,
  Point,
  PointsFrancais,
  PointsMathematiques,
  PourcentagePresence,
  Precompte,
  PreferencesHoraires,
  PremierCours,
  Prenom,
  Prenom2,
  PrenomDelegue,
  PrenomDoublon,
  PrenomPatron,
  PrenomSa,
  Presence,
  PresencesX,
  President,
  Prestations,
  PrestationsContrat,
  PrestationsGlobales,
  Print,
  Prive,
  Profession,
  ProfessionFormateur,
  ProfessionsFormateurs,
  ProjetCoFinancement,
  ProjetFinancement,
  ProjetsFinancement,
  QueryInvalid,
  QueryMustStartWithSelect,
  QueryParamNotClosed,
  QueryParamNotOpened,
  QuickAccess,
  Quota,
  Quotas,
  RCons,
  RE,
  RH,
  ROb,
  Raison,
  Rapport,
  Rattrapage,
  RecapitulatifAnnee,
  RecherceMiseAJourDeMasse,
  Recherche,
  RecherchePatron,
  RechercheUtilisateurEnCours,
  ReferToAdminOrRelogin,
  Referentiel,
  Referentiels,
  RegistreNational,
  RegistreNationalDoublon,
  ReinitConfirmMessage,
  ReinitMatieres,
  RemCons,
  RemOb,
  Remarque,
  RemarqueCentre,
  RemarqueCentreAgrement,
  RemarqueConseiller,
  RemarqueConseillerAgrement,
  Remarques,
  Remediation,
  RemediationTJ,
  Remise,
  RemoveAll,
  RemoveAllText,
  RemoveElementText,
  RempMoinsCE,
  RemplacaPlusApp,
  Remplacant,
  RemplacantPlusApp,
  RemplacantPlusCE,
  Remplace,
  RemplaceMoinsApp,
  RemplacePlusCE,
  Remplacement,
  Remplacements,
  Reporte,
  Representant,
  Representants,
  Requete,
  RequeteInvalide,
  RequeteNonAutorisee,
  RequeteTropLongue,
  RequeteValide,
  Required,
  Reset,
  Responsable,
  Responsables,
  Resultat,
  ResultatFinAPPObtenu,
  ResultatType,
  ResultatsBloques,
  Retour,
  Reussite,
  RouteConfirmDialogTitle,
  Rupture,
  RuptureContrat,
  S1Agr,
  S1Loc,
  S2Agr,
  S2Loc,
  SMS,
  SamShortName,
  Samedi,
  SaveDone,
  SaveSuccess,
  Search,
  SearchCriteriaPanel_AddCriteria,
  SearchCriteriaPanel_No,
  SearchCriteriaPanel_Search,
  SearchCriteriaPanel_SearchModes_Between,
  SearchCriteriaPanel_SearchModes_Contains,
  SearchCriteriaPanel_SearchModes_EndWith,
  SearchCriteriaPanel_SearchModes_Equals,
  SearchCriteriaPanel_SearchModes_Greater,
  SearchCriteriaPanel_SearchModes_GreaterOrEquals,
  SearchCriteriaPanel_SearchModes_GreaterThan,
  SearchCriteriaPanel_SearchModes_GreaterThanOrEquals,
  SearchCriteriaPanel_SearchModes_Less,
  SearchCriteriaPanel_SearchModes_LessOrEquals,
  SearchCriteriaPanel_SearchModes_LessThan,
  SearchCriteriaPanel_SearchModes_LessThanOrEquals,
  SearchCriteriaPanel_SearchModes_NotContains,
  SearchCriteriaPanel_SearchModes_NotEquals,
  SearchCriteriaPanel_SearchModes_StartWith,
  SearchCriteriaPanel_Yes,
  SearchPlaceholder,
  SecteurClasseHomogeneDetailPage_StadeLibelleExistsError,
  SecteurHomogene,
  SecteursClasseHomogene,
  SecteursMetiers,
  Section,
  SectionConfirmMessage,
  SelectAll,
  SelectApprenantsAndClickOnSave,
  SelectAtLeastOne,
  SelectPostalCodeFirst,
  Selection,
  Semaine,
  Semestre,
  SendAnSMS,
  SendAnSMSToSelected,
  Service,
  ServicesAlternance,
  SeuilReport,
  SeuilReussite,
  Sexe,
  Signaletique,
  SignatureSms,
  Signe,
  Societe,
  Soldes,
  Spec,
  Specificite,
  SpecificiteCours,
  Stade,
  StadeFormationClasseError,
  Stades,
  StatistiquePresenceAbsence,
  Statistiques,
  Status,
  Statut,
  StatutAgrement,
  StatutInscription,
  StatutLocalisationAgrement,
  StatutSocial,
  StatutStage,
  StatutsSociaux,
  Suivi,
  SuiviApprenant,
  SuiviApprenants,
  SuiviSms,
  TJ,
  Tab,
  TableCriterias,
  TableResults,
  Tel,
  TelApp,
  TelAutre,
  TelCE,
  TelCentral,
  TelFCO,
  TelPrive,
  TelPro,
  Telephone,
  TelephonePro,
  Test,
  Title,
  Titre,
  Titres,
  TitresPedagogiques,
  ToggleFixedTooltip,
  Total,
  TotalAgr,
  TotalAgrement,
  TotalApresDeliberation,
  TotalCourseType,
  TotalDispense,
  TotalExamen,
  TotalExamenDiff,
  TotalHReel,
  TotalHeures,
  TotalLoc,
  TotalMax,
  TotalMinerval,
  TotalMontantDu,
  TotalMontantPaye,
  TotalNbHeures,
  TotalNbHeuresDiff,
  TotalPoints,
  TotalTjDiff,
  Type,
  TypeContrat,
  TypeCours,
  TypeCours_CharacterLimitCode,
  TypeCours_CharacterLimitLibelle,
  TypeDispense,
  TypeDisplay,
  TypeEvaluation,
  TypeFinancement,
  TypeIncoherence,
  TypeProjet,
  TypeRemplacement,
  TypeReussite,
  TypesCours,
  TypesEnseignement,
  TypesEvaluation,
  TypesFinancement,
  URL,
  UnFixMenuTooltip,
  UniteActivite,
  Update,
  Utilisateur,
  Utilisateurs,
  Utilitaire,
  Vacataires,
  ValidationCE,
  ValidationDeMasse,
  Valide,
  ValidityDates,
  ValueNotPartOfAuthorizedOnes,
  VenShortName,
  Vendredi,
  Visite,
  VisiteExterieure,
  VisitesExterieures,
  Voir,
  VoirApprenant,
  Website,
  Weekend,
  heure1,
  heure10,
  heure11,
  heure12,
  heure13,
  heure2,
  heure3,
  heure4,
  heure5,
  heure6,
  heure7,
  heure8,
  heure9,
  idcentre,
  PleaseChooseClasse,
  PleaseChooseCentre,
  BackendCodes_LocalisationLinked,
  Available,
  CannotRemoveInscription,
  Heure,
  Motif,
  IncoherenceCentre,
  CentreUtilisateurFlag,
  IdtypeIncoherence,
  SearchCriteriaPanel_SearchModes_In,
  SearchCriteriaPanel_SearchModes_NotIn,
  CalculCoherenceError,
  ReadyToValidate,
  Validate,
  Unlock,
  Lock,
  ImpressionsLitePresence,
  Personnalisee,
  Semestre1,
  Semestre2,
  PeriodeImpression,
  TypeCoursAutreClasseAReferencer,
  CalculTotalGrillePresence,
  StatAgrementSubvention,
  Imprimer,
  AnneeAcademique,
  BackendCodes_FeuillePresenceStartDateGreaterThanEndDate,
  BackendCodes_NoCourseForPeriod,
  BackendCodes_NoApprenantForClasse,
  SelectFileText,
  BrowseFileText,
  Inscrits,
  ApprenantList,
  AddApprenants,
  Cancel,
  SelectionApprenants,
  UnSelectAll,
  Neutralise,
  Precedent,
  Suivant,
  TotalSansNeutralisation,
  EvalVide,
  ConfirmSaveEvaluationListTitle,
  ConfirmSaveEvaluationListMessage,
  CoteNombrePositifOuLettreAcceptee,
  GreaterThanCoteMax,
  DownloadBeid,
  BackendError_NoCourseForPeriod,
  BackendError_NoApprenantForClasse,
  ApprenantTooOld,
  ApprenantTooYoung,
  DateDebutGreaterThanDateFin,
  DateFinGreaterThanDateDebut,
  CoteRemediation,
  PointsLogique,
  DateEvaluation,
  CannotEditHoraireBecauseReplacement,
  TypeEvaluationNotInList,
  ClasseNotInList,
  ClasseMatiereNotInList,
  CodeMetier,
  Idclasse_Table,
  NbRemplacants,
  Remplacants,
  RemplacementNonPayes,
  RemplacementPayes,
  Sess1,
  Sess2,
  CalendriersSearch,
  HeureDebut,
  Visites,
  ValidationMasse_Masculin,
  ValidationMasse_CodeClasse,
  ValidationMasse_Classe,
  EmailIfapme,
  TemporaryPassword,
  BanniDuCentre,
  AzureActive,
  BackendError_ActiveReportAlreadyExists,
  Refresh,
  IsLinkedToClasse,
  CalculHoraireManquant,
  CodeConseiller,
  IdStatutLocalisation,
  ErrorCodeClasseResultatLock,
  ApprenantIdCardIsDifferent,
  FormateurIdCardIsDifferent,
  RepresentantIdCardIsDifferent,
  HardDelete,
  ClasseMatiereSaveContratNotNullFormateurNull,
  ClasseMatiereSaveTravailRequired,
  ClasseMatiereSaveExamenRequired,
  ClasseMatiereSaveRemplacementExisting,
  ClasseMatiereSaveExportGapExisting,
  InscriptionMasse_Masculin,
  SelectionSchool,
  SelectEcole,
  SelectSchool,
  CalculDates,
  Azure,
  ActionCode_AZURE,
  ChangementClasseHasEvaluationsToDeleteTitle,
  ChangementClasseHasEvaluationsToDelete,
  MarkAsWrong,
  AnneeScolaireDoublon,
  CentresDoublon,
  BackendError_FeuillePresenceStartDateGreaterThanEndDate,
  RefreshPresence,
  RefreshBulletin,
  NumberErrorType,
  AzureInformation,
  PrincipalName,
  DisplayName,
  JobTitle,
  ConfirmAzureActivate,
  ConfirmAzureDeactivate,
  CreateAzure,
  AccountEnabled,
  DateModification,
  RemplacementOverSchoolYear,
  Duplicate,
  AssignerPlusieursMatieres,
  NoMatiereSelected,
  TotalPercent,
  InscriptionInProgress,
  ErrorWhileInscriptionMasse,
  Close,
  Sur,
  InscriptionDone,
  SearchCriteriaPanel_Reset,
  SearchCriteriaPanel_And,
  SearchCriteriaPanel_Or,
  CreerMailApprenants,
  CreerMailAll,
  DonneesClasse,
  AucunEmailTrouvé,
  ApprenantNameExists,
  NoCriteriaFilled,
  SearchNom,
  SearchPrenom,
  BicChangedAccountUnchanged,
  SearchDateNaissance,
  SearchRegistreNational,
  InscriptionHasDispensesMessage,
  InscriptionHasDispensesTitle,
  CodePostalSearchCriterias_CodePostal,
  CodePostalSearchCriterias_Commune,
  CodePostalSearchCriterias_Localite,
  CodePostalSearchCriterias_Pays,
  CodePostalSearchCriterias_Actif,
  EntiteCentre,
  Enveloppe,
  InscriptionDegreModifiedMessage,
  InscriptionDegreModifiedTitle,
  InscriptionDegreModifiedAndHasDispenseMessage,
  InscriptionDegreModifiedAndHasDispenseTitle,
  Attention,
  ValidationDialogMessage,
  IdTypeRemplacement,
  NbHeuresAPayer,
  ValidationDirection,
  ValidationDirectionDate,
  ValidationDirection1Sess,
  ValidationDirection2Sess,
  IdLegitimite,
  AccessLevelHope,
  AccessLevelWalter,
  IfapmeSide,
  Action,
  History,
  Age,
  Years,
  XYear,
  EmergencyContact,
  At,
  By,
  PrintFicheTutelle,
  CreateSuivi,
  SendAnEmail,
  NoSelectedApprenantHasAnEmail,
  BankAccountBeneficiary,
  GlobalActions,
  SiegeSociauxLieuxFormation,
  NumeroBce,
  NoSelectedSiegeSocialHasAnEmail,
  C,
  A,
  NomLieuFormation,
  Enseigne,
  SendAnEmailToLieux,
  SendAnSMSToLieux,
  NationaliteNonBelge,
  Apprenant_Add_Acquis,
  Apprenant_Add_AcquisPro,
  Apprenant_Add_Evaluation,
  Apprenant_Add_Formation,
  Apprenant_Add_Inscription,
  Apprenant_Add_Justificatif,
  Apprenant_Add_Representant,
  Apprenant_Add_Suivi,
  Apprenant_Add_ValidationCE,
  Apprenant_Create_Azure,
  Apprenant_Create_Signaletique,
  Apprenant_Delete_Acquis,
  Apprenant_Delete_AcquisPro,
  Apprenant_Delete_Evaluation,
  Apprenant_Delete_Formation,
  Apprenant_Delete_Inscription,
  Apprenant_Delete_JustificatifAbsence,
  Apprenant_Delete_Representant,
  Apprenant_Delete_Suivi,
  Apprenant_Delete_ValidationCE,
  Apprenant_Send_Email,
  Apprenant_Send_Sms,
  Apprenant_SoftDelete_Inscription,
  Apprenant_Update_Acquis,
  Apprenant_Update_AcquisPro,
  Apprenant_Update_Evaluation,
  Apprenant_Update_Formation,
  Apprenant_Update_Inscription,
  Apprenant_Update_JustificatifAbsence,
  Apprenant_Update_Representant,
  Apprenant_Update_Signaletique,
  Apprenant_Update_Suivi,
  Apprenant_Update_ValidationCE,
  Copy,
  I,
  SiegeSocial,
  StillToDev,
  LieuxFormations,
  ChefsEntreprise,
  Contacts,
  Assurances,
  Documents,
  DenominationSociale,
  TypeEntreprise,
  FormeJuridique,
  Taille,
  NumeroOnss,
  Inactif,
  EnCours,
  StatutContrat,
  Rompu,
  Suspendu,
  Termine,
  JamaisDeContrat,
  SansSuite,
  StatutInscriptions,
  AucuneInscriptionEnCours,
  AuMoinsUneInscriptionsEnCours,
  AuMoinsUneInscriptionsEnAttente,
  PrintFicheEntreprise,
  CoordonneesSiege,
  SendToAddress,
  Fonction,
  ContactTelGsm,
  DonneesDeBase,
  IsCompanySearch,
  Contrats,
  PlanFormation,
  Resultats,
  Tuteurs,
  Offres,
  NumeroUe,
  CapaciteFormative,
  PersonneContact,
  CoordonneesLieuFormation,
  AddSiegeSocial,
  AddLieuFormation,
  NoSelectedLieuxFormationHasAnEmail,
  SauverEtNouveau,
  CannotAddLieuToSiegeSocialInactif,
  DesactiverSiegeSocialText,
  EtesVousSureDesactiverSiegeSocial,
  ReactiverSiegeSocialText,
  ReactivationSiegeSocialTitle,
  PersonneReferenceIfapme,
  SusceptibledAccueillirUnApprenant,
  PasSusceptibledAccueillirUnApprenant,
  AccueilApprenant,
  ResponsableAdministratif,
  NomFormation,
  DegreClasseDifferentDegre,
  Cop,
  DateInvalide,
  DeceasedLearner,
  IsDeceasedConfirmation,
  BeneficiaryName,
  SiegeSocial_Create_Detail,
  SiegeSocial_Update_Detail,
  SiegeSocial_Enable_Detail,
  SiegeSocial_Disable_Detail,
  LieuFormation_Create_Detail,
  LieuFormation_Update_Detail,
  LieuFormation_Enable_Detail,
  LieuFormation_Disable_Detail,
  ExperienceProfessionnelle,
  NewExperiencePro,
  AcquisIfapme,
  Operateur,
  Examinateur,
  DecisionDossierAdmission,
  ResultatFinal,
  ResultatValide,
  AnneeFormation,
  Niveau,
  Forme,
  Option,
  NewAcquis,
  NewAcquisIfapme,
  Commentaire,
  BilanCompetence,
  DossierAdmission,
  ExamenEntreeFCE,
  AnneeFormationSuivie,
  AnneeFormationSuivieIfapme,
  DiplomeCertification,
  DiplomeCertificationIfapme,
  InvalidBCE,
  AtLeastOneCourrier,
  BCEAlreadyExists,
  Apprenant_Add_ExperienceProfessionnelle,
  Apprenant_Add_Signaletique,
  Apprenant_Add_BilanCompetence,
  Apprenant_Add_DossierAdmission,
  Apprenant_Add_ExamenEntreeFce,
  Apprenant_Add_ExamenEntree,
  Apprenant_Add_AnneeFormationSuivie,
  Apprenant_Add_AnneeFormationSuivieIfapme,
  Apprenant_Add_DiplomeCertification,
  Apprenant_Add_DiplomeCertificationIfapme,
  Apprenant_Delete_ExperienceProfessionnelle,
  Apprenant_Delete_BilanCompetence,
  Apprenant_Delete_DossierAdmission,
  Apprenant_Delete_ExamenEntreeFce,
  Apprenant_Delete_ExamenEntree,
  Apprenant_Delete_AnneeFormationSuivie,
  Apprenant_Delete_AnneeFormationSuivieIfapme,
  Apprenant_Delete_DiplomeCertification,
  Apprenant_Delete_DiplomeCertificationIfapme,
  Apprenant_Update_ExperienceProfessionnelle,
  Apprenant_Update_BilanCompetence,
  Apprenant_Update_DossierAdmission,
  Apprenant_Update_ExamenEntreeFce,
  Apprenant_Update_ExamenEntree,
  Apprenant_Update_AnneeFormationSuivie,
  Apprenant_Update_AnneeFormationSuivieIfapme,
  Apprenant_Update_DiplomeCertification,
  Apprenant_Update_DiplomeCertificationIfapme,
  Apprenant_Update_ParcoursFormation,
  NotAllowed,
  UserNotMatchingApp,
  OrganisationDeLaFormation,
  OffresDemandes,
  Denomination,
  Clear,
  SearchRepresentants,
  Select,
  SelectedRepresentants,
  SearchInstitutions,
  SelectedInstitutions,
  RepresentantLegal,
  Lien,
  Parent,
  GrandParent,
  Fratrie,
  TuteurLegal,
  AutreLienFamiliale,
  AutreLien,
  Institution,
  Personne,
  AtLeastOneContactField,
  AlreadyHasEmergencyContact,
  NoAdressSelected,
  SelectAdresse,
  SelectAnAdress,
  NoAdresseInPersonne,
  PleaseCreateAdresse,
  CreateAdresse,
  Personnes,
  Adresses,
  NumerosTelephone,
  Emails,
  TelephoneLinked,
  ReplaceBy,
  TelephoneUsedWarning,
  EmailLinked,
  EmailUsedWarning,
  Domicile,
  Courrier,
  NoTelephoneAdded,
  NoTelephoneAddedMessage,
  NoEmailAddedMessage,
  NoEmailAdded,
  Suivis,
  RealisateurSuivi,
  TypeSuivi,
  SousTypeSuivi,
  Contenu,
  LieuRencontre,
  CommissionTutelle,
  DateEcheance,
  TypeAction,
  ResponsableAction,
  RemarqueAction,
  EtatAction,
  DateAction,
  EditAction,
  HiddenContent,
  IdrealisateurSuivi,
  DecisionCommissionTutelle,
  Representant_Personne,
  Representant_Institution,
  Representant_PersonneContact,
  VoirFormateur,
  VoirRepresentant,
  Personne_Update_Adresses,
  Personne_Update_Telephones,
  Personne_Update_Emails,
  Personne_Update_ComptesBancaires,
  OnlyUserSuivis,
  DateEntreeReseau,
  SousStatut,
  RechercheEntreprise,
  DateSortieReseau,
  MotifSortieReseau,
  NbJours,
  SortieReseau,
  ParcoursFormation,
  Statuts,
  StatutsMotifs,
  MotifSortie,
  Par,
  HistoriqueChangement,
  NouveauParcoursFormation,
  ParcoursInterne,
  StatutDemande,
  Faite,
  PasFaite,
  EnAttente,
  AValider,
  AEnvoyerBrouillon,
  Historique,
  Collaborateur,
  Collaborateurs,
  Departement,
  Poste,
  AccesApplicatif,
  Profil,
  Services,
  ServicesLies,
  DelegationSignature,
  UserIsFormateurWarning,
  UserIsNotFormateurWarning,
  ServiceSearchCriterias_Nom,
  DateSortie,
  DateEntree,
  ServerValidationError_Required,
  VoirCollaborateur,
  AddDemandeInscription,
  NouvelleDemandeInscription,
  MeDelierApprenant,
  MAssignerApprenant,
  CreerAPartirdePersonne,
  SupprimerLiens,
  Tuteur,
  ChefEntreprise,
  DelegueTutelle,
  OrigineSuivi,
  IdtypeSuivi,
  Encodeur,
  Idencodeur,
  Validee,
  Annulee,
  StatutCourant,
  BrouillonPret,
  DemanderLaValidation,
  EnvoyerAuxCentres,
  DernierParcours,
  Cv,
  AnneesExperience,
  Tutorat,
  Vdc,
  TuteurLieuIdCardIsDifferent,
  TuteurNameExists,
  SortieEntreprise,
  PersonneContactPrincipale,
  ResponsableDerogation,
  DateDerogation,
  DelierTuteur,
  CreerAPartirdeTuteur,
  CapaciteFormativeNonRemplie,
  DemandeInscription,
  Remove,
  SendToCenter,
  CF,
  DernierLieuFormation,
  EnseigneDernierLieuFormation,
  TuteurIdCardIsDifferent,
  LieuFormation_Update_Tuteurs,
  Tuteur_Create_Detail,
  Tuteur_Update_Detail,
  Tuteur_Create_LieuxFormation,
  Tuteur_Update_LieuxFormation,
  Tuteur_Delete_LieuxFormation,
  CreerTuteur,
  ConsulterFichePersonne,
  MiseAJourFichePersonne,
  EncodageProvisoireAdministratif,
  Brouillon,
  CancelConfirmationMessage,
  CancelDialogTitle,
  SortieReseauConfirm,
  SortieReseauConfirmMessage,
  ViewAction,
  CreateNewDemande,
  CreateNewDemandeMessage,
  ChefEntreprisePrincipal,
  CreerAPartirdeChefEntreprise,
  ChefEntrepriseSiegeIdCardIsDifferent,
  DelierChefEntreprise,
  BackendError_AnotherTuteurIsMain,
  DernierSiegeSocial,
  FonctionDernierSiegeSocial,
  ChefEntrepriseIdCardIsDifferent,
  SearchChefEntreprises,
  SelectedChefEntreprises,
  EstAussiTuteur,
  VoirFormes,
  VoirChefEntreprise,
  VoirTuteur,
  ContactGsm,
  ContactTel,
  ContactFonction,
  LieuFormationPrincipal,
  TuteurPrincipal,
  Generalites,
  StatutContrat_EnCours,
  StatutContrat_Termine,
  StatutContrat_Rompu,
  StatutContrat_Suspendu,
  StatutContrat_NonDemarre,
  StatutContrat_SansSuite,
  MarquerSansSuite,
  DateRupture,
  DatePriseActe,
  SearchLieuFormations,
  SelectedLieuxFormation,
  SearchApprenants,
  SelectedApprenants,
  SearchTuteurs,
  AddTuteur,
  Suspensions,
  SuspensionContratSupprimee,
  SuspensionContrat,
  ServerValidationError_SuspensionIntersection,
  Contrat_Create_Detail,
  Contrat_Update_Detail,
  Contrat_Create_Suspensions,
  Contrat_Update_Suspensions,
  Contrat_Delete_Suspensions,
  Suspension,
  Avenant,
  Impression,
  AddContrat,
  NomMetier,
  CodeContrat,
  DenominationSiegeSocial,
  ChoisirLieuFormation,
  UserType,
  UserType_Centre,
  UserType_Service,
  SortieImpossibleContratEnCours,
  ConttratEnCoursACetteDate,
  Beneficiaire,
  PasDeContacts,
  Source,
  Masque,
  ActionARealiser,
  LieuFormationAgrements,
  AddAgrement,
  Offre,
  DateDecision,
  Complet,
  PartielA,
  PartielB,
  PartielAB,
  StatutAgrement_PourOffre,
  StatutAgrement_EnInstance,
  StatutAgrement_Provisoire,
  StatutAgrement_Agree,
  StatutAgrement_NonAgree,
  StatutAgrement_Suspendu,
  StatutAgrement_Retrait,
  ActionPourLaSelection,
  DateReception,
  MetierStade,
  CapForm,
  DossierComplet,
  ReferentIfapme,
  DemandeSectorielle,
  DecisionAgrement,
  AgrementPartiel,
  DateEnvoiD1,
  DateReceptionAvis,
  DateVisite,
  PratiqueSimulee,
  CompteRendu,
  MotifsNonAgrement,
  DateEnvoiD3,
  DateRetrait,
  MotifRetrait,
  Retrait,
  AgrementPartielA,
  AgrementPartielB,
  AddSuspension,
  SuspensionLieuFormationAgrement,
  EnCoursCreation,
  Active,
  Inactive,
  DateCv,
  Apprenant_Update_PriContrat,
  Apprenant_Update_AriContrat,
  RemarqueService,
  ImprimerFicheTutelle,
  CreateSuiviForNbApprenants,
  ImprimerInscription,
  ImprimerFicheHoraire,
  CreationMultiple,
  DispenseChomage,
  DureeParcours,
  CommissionParitaire,
  HeuresHebdomadaires,
  Clauses,
  LieuxDates,
  DateSignature,
  LieuSignature,
  DatePassageB,
  DatePassageC,
  Bareme,
  RemunerationReelle,
  Baremes,
  CalculeApresCreation,
  ContratAlternanceCC,
  ConventionStageCS,
  StagePratiqueProPP,
  ConventionFALTCF,
  StageDecouverteMetierDM,
  UserIsNotRiOrCalc,
  DonneesJoursHeures,
  NbMaxHeuresFormationComplete,
  NbMinHeuresAnnee,
  NbHeuresPrestees,
  NbHeuresPrevues,
  ServerValidationError_MetierNotCorrespondingAgrements,
  ServerValidationError_MetierNotCorrespondingTuteurs,
  ServerValidationError_OnlyOneMainLf,
  ServerValidationError_OnlyOneMainTuteur,
  AccessLevelBoth,
  DateRemise,
  SiegesSociaux,
  SPP,
  ServerValidationError_TuteursCannotBeSpp,
  ServerValidationError_LieuFormationContratsMustCorrespondToDecision,
  ServerValidationError_LieuFormationDoesNotHaveAnyValidAgrements,
  DateTransmission,
  DetailsPrestations,
  Jour1,
  Matin,
  ApresMidi,
  Jour2,
  Jour3,
  Jour4,
  Jour5,
  Jour6,
  Jour7,
  Jour8,
  Jour9,
  Jour10,
  DemandeConfirmation,
  CompagnieAssurance,
  NumeroPolice,
  Assurance,
  OperateurFormation,
  CannotBeNegative,
  CannotBeGreaterThan,
  PcFormationEmployeur,
  PcFormationOperateur,
  InterventionFinanciere,
  SiegeSocialNoAssurance,
  CategorieStatut,
  SuivisApprenants,
  StatutApprenant,
  CannotBeUnderBareme,
  MarquerSansSuiteConfirm,
  MarquerSansSuiteConfirmMessage,
  ActionCode_OFFRE,
  CapaciteAtteinte,
  NomStade,
  OffreActive,
  Call,
  ErrorForOneOrMoreTuteurs,
  ErrorForOneOrMoreLieuxFormation,
  MustBeWithinContractPeriod,
  MustBeAfterRupture,
  ServerValidationError_DateMustBeWithinRange,
  ContratAlreadySuspended,
  ContratSoonSuspended,
  SuivisSiegesLieux,
  LieuPrincipal,
  IdsiegeSocial,
  IdlieuFormation,
  IsApprenant,
  IsFormateur,
  IsRepresentant,
  IsUser,
  IsChefEntreprise,
  IsTuteur,
  ServerValidationError_AgrementWithSameMetierAlreadyActive,
  Initiateur,
  MotifPatron,
  MotifJeune,
  CALCRIActant,
  AgrementsOffreActive,
  AgrementsOffreActiveConfirm,
  NotAuthorizedForModule,
  TuteurDisablingForSortie,
  OK,
  TuteurInactifARemplacer,
  ContratTuteurInactifARemplacer,
  VeuillezVerifierLesTuteursDeCeContrat,
  VeuillezVerifierLesLieuxFormationDeCeContrat,
  TuteurCapaciteFormativeAtteinte,
  LieuFormationCapaciteFormativeAtteinte,
  CpHorsMetier,
  Inscrit,
  Envoyee,
  EnAbandon,
  EnValidation,
  EnCorrection,
  AjouterValeurALaListe,
  ValeurDoitEtreUnNombre,
  AddAvenant,
  Types,
  DatePriseEnCours,
  Avenants,
  CreerAPartirdeContact,
  TypesAvenant,
  NumeroAvenant,
  DateCommissionTutelle,
  DecisionInstitut,
  ResponsableValidation,
  MonTableauDeBord,
  Dossiers,
  ApprenantRechercheEntreprise,
  DocumentsManquants,
  PratiqueProfessionnelle,
  ServerValidationError_BceAlreadyExists,
  SauverEtDupliquer,
  DateCannotBeInFurture,
  EnvoyerEnValidation,
  Valider,
  Invalider,
  DetailParcours,
  NouvelleFormation,
  FormationExistante,
  DetailsInscription,
  ChoixFormation,
  MisEnSortie,
  DemandeSuppression,
  EnRenvoi,
  Supprime,
  RefuseCentres,
  RefuserDemandeConfirmTitle,
  RefuserDemandeConfirmMessage,
  MesDemandes,
  DemandesServices,
  MasquerDemandeConfirmTitle,
  MasquerDemandeConfirmMessage,
  AfficherMasquees,
  LocaliteSignature,
  RemarqueInvalidation,
  InvaliderDemande,
  DefinirCommeTuteur,
  AucunCentrePourLesDonnéesChoisies,
  LieuFormationAgrementOffreMessage,
  LieuFormationAgrementOffreTitle,
  CapaciteFormativeLieuFormationVaEtreDepassee,
  CapaciteFormativeLieuFormationEstDejaDepassee,
  DatePriseEffet,
  MotifsRetraitAgrement,
  IFAPME,
  CEFA,
  RetraitDejaEffectueSurUnAgrementSelectionne,
  DateDecisionRetrait,
  OrganismeResponsableRetrait,
  AgrementRetraitWarningTitle,
  AgrementRetraitWarningMessage,
  DisableLieuFormationTitle,
  DisableLieuFormationMessage,
  ModifierSuspension,
  AddVisite,
  ServerValidationError_RegistreNationalAlreadyExists,
  PermisVoitureB,
  PermisCyclomoteurAM,
  CommissionsParitaires,
  AtLeastOneRegNatFields,
  ReassignerCOP,
  ReassignationCop,
  COPsARemplacer,
  CopRemplacant,
  ReassignationPri,
  PriRemplacant,
  PRIsARemplacer,
  ReassignerPRI,
  ReassignerARI,
  ReassignationAri,
  ARIsARemplacer,
  AriRemplacant,
  MaintenirOffreTitle,
  MaintenirOffreMessage,
  LieuFormationAgrementCapaciteFormativeAtteinte,
  TypeSortie,
  ContactIdCardIsDifferent,
  PassportDrive,
  Permis,
  AutoEcole,
  RemiseCheque,
  InscriptionEffective,
  DatePermisTheorique,
  DatePermisPratique,
  ChequeReceptionForem,
  ResultatPermisTheorique,
  ResultatPermisPratique,
  DemandeNouveauCheque,
  EmailPromotionnel,
  NePlusEnvoyer,
  Apprenant_Create_PassportDrive,
  Apprenant_Update_PassportDrive,
  PasDeCopAssigné,
  UniquementSPP,
  CapaciteNonAtteinte,
  Legende,
  ServerValidationError_AgrementPourOffreMustBeChanged,
  ServerValidationError_AnAgrementIsSPPButContratIsNotPP,
  Tous,
  DoublonAdresseACorriger,
  CorrigerDansLaFichePersonne,
  DoublonAdresse,
  VeuillezChoisirAdresseCorrecte,
  CorrigerDoublon,
  BackendError_CannotDeleteLfAgrementContratsLinked,
  ContactsSiegesLieux,
  ServerValidationError_NumeroIdentificationAlreadyExists,
  CopPrincipal,
  SocietesExternes,
  SocieteExterne,
  PersonneTrouvee,
  VeuillezInsererCarteIdentite,
  Ou,
  Confirmer,
  AucunePersonneTrouvee,
  AucunePersonneTrouveePourNiss,
  PlusieursPersonnesTrouvees,
  VeuillezContacterCentres,
  AddFormeConfirmMessage,
  MarquerSuiviCommeFaitConfirmMessage,
  MarquerSuiviCommeFaitConfirmTitle,
  MarquerCommeFait,
  MasquerDemande,
  VoirInscription,
  ApprouverEtCreerInscription,
  RefuserInscription,
  Realisee,
  DateEcheanceDepassee,
  NonRealisee,
  ActionsQuiMeSontAssignees,
  ActionsAssigneesServices,
  PasDateEcheance,
  DateEcheanceNonDepassee,
  DateEcheanceNonDepasseeValue,
  DateCannotBeInPast,
  MasquerSuiviConfirmTitle,
  MasquerSuiviConfirmMessage,
  MasquerSuivi,
  AfficherMasques,
  ActionRealisee,
  ActionNonRealisee,
  FormateurConcerne,
  ActionsAssigneesCentres,
  EHopeApprenantSearchType_CURRENT,
  EHopeApprenantSearchType_PAST,
  EHopeApprenantSearchType_BOTH,
  MesApprenants,
  AucuneCpDansCeSiegeSocial,
  Referents,
  Referent,
  ModificationDate,
  BackendError_InscriptionDejaCreeAPartirDemandeInscription,
  AucunServicePourCetApprenant,
  MarquerAbsenceCommeVueConfirmTitle,
  MarquerAbsenceCommeVueConfirmMessage,
  MarquerCommeVu,
  AbsencesInjustifiees,
  DeltaDernierAvertissement,
  DernierAvertissement,
  MesLieuxFormation,
  VeuillezEffectuerUneRecherche,
  SortieImpossible,
  MarquerSortieConfirmTitle,
  MarquerSortieHopeConfirmMessage,
  MarquerSortie,
  DashboardDossierType_SortieApprenant,
  SortieEnCours,
  VoirContratsApprenant,
  RefuserSortie,
  MarquerSortieWalterConfirmMessage,
  SortieAnnulee,
  VoirInscriptionsApprenant,
  ConfirmerSortie,
  RefuserSortieConfirmTitle,
  RefuserSortieConfirmMessage,
  SortieImpossibleWalter,
  VoirParcoursFormation,
  Exterieur,
  Supprimer,
  DemanderSuppression,
  ContenuCache,
  ContenuCachePour,
  TousSaufCopCalc,
  StageObservationSO,
  ContratAlternanceCommunCCE,
  ConventionStageExterneCSE,
  MaitreStage,
  DureeExperiencePro,
  GsmTel,
  NbMaxHeures,
  NbMinHeures,
  SelectedSiegeSocials,
  SearchSiegeSocials,
  DonneesContrat,
  DureeSousContrat,
  NomChefEntreprise,
  PrenomChefEntreprise,
  TypeCoursDemandeSeulement,
  DepuisNJours,
  DepuisAujourdhui,
  ActionCode_HISTORY,
  ActionCode_CONTRATS,
  ActionCode_STATUTMOTIFS,
  ActionCode_PARCOURSFORMATION,
  ActionCode_CHEFENTREPRISE,
  ActionCode_SIEGESOCIAUX,
  ActionCode_CONTACTS,
  ActionCode_SIEGESSOCIAUX,
  ActionCode_LIEUXFORMATION,
  ActionCode_MULTIPLE,
  ActionCode_AVENANTS,
  ActionCode_SUSPENSIONS,
  ActionCode_DOCUMENTS,
  ActionCode_PRATIQUEPRO,
  ActionCode_DOSSIERS,
  ActionCode_SUIVIS,
  ActionCode_DASHBOARD,
  ActionCode_CONTRAT,
  ActionCode_TUTEUR,
  ActionCode_OFFRES,
  ActionCode_ASSURANCES,
  ActionCode_CHEFSENTREPRISE,
  ActionCode_SERVICES,
  NomFichier,
  DateUpload,
  UtilisateurUpload,
  ErrorWhileDeletingFile,
  ErrorWhileDownloadingFile,
  Parcourir,
  ChoisissezUnFichier,
  UploadFichier,
  Fichier,
  FileName,
  UploadUser,
  UploadDate,
  IdtypeApprenantFichier,
  UploadFichiers,
  MaxFileSizeReached,
  IdtypeAcquisFichier,
  IdtypeSuiviFichier,
  Document,
  DocumentCompteRendu,
  ActionCode_INSTITUTION,
  ActionCode_JOBS,
  IdtypeSiegeSocialFichier,
  IdtypeLieuFormationFichier,
  DocumentRupture,
  AutresDocuments,
  Remis,
  TypeApprenantFichier_DOSSIER_SCOLAIRE,
  TypeApprenantFichier_CARTE_IDENTITE,
  TypeApprenantFichier_FICHE_FSE,
  TypeApprenantFichier_ROI_CARNET_PEDA,
  TypeApprenantFichier_CHARTE_ENGAGEMENT,
  AvisAttestationPF,
  DocumentsReglementaires,
  MetierContrat,
  StadeContrat,
  ServiceContrat,
  EnrechercheEntreprise,
  RegistreNationalOrEmpty,
  EnseigneLieuFormation,
  StatutContrat_NON_AGREE,
  StatutContrat_RET,
  StatutContrat_TRANSFERT_CEFA,
  StatutAgrement_SansSuite,
  ServiceNom,
  MesContrats,
  NomInstitution,
  NomApprenant,
  PrenomApprenant,
  ChefEntrepriseFullName,
  ContactFullName,
  Idpri,
  Idari,
  AnneeScolaireCriteria,
  ClasseCriteria,
  CentreCriteria,
  DegreCriteria,
  MetierCriteria,
  StadeCriteria,
  SearchCriteriaPanel_SearchModes_NestedDefault,
  IdsousTypeSuivi,
  Idservice,
  SuiviAction,
  SuiviAction_Realisee,
  SuiviAction_ARealiser,
  SuiviAction_Aucune,
  IsContact,
  ServerValidationError_CannotDisableSiege,
  ServerValidationError_CannotDisableLieu,
  DocumentPhysique,
  PasDeNumero,
  ServerValidationError_DemandeAlreadyExists,
  Envoyeur,
  Apprenant_Add_JustificatifAbsence,
  ServerValidationError_NumeroUeAlreadyExists,
  Contrat_Create_Avenant,
  Contrat_Update_Avenant,
  Contrat_SoftDelete_Avenant,
  StatutBCE,
  ActionCode_SUIVIS_SIEGE_LIEU,
  DateCannotBeGreaterThan1YearInFurture,
  CapaciteFormativeLieuFormationAgrementVaEtreDepassee,
  LieuFormationInactif,
  DisableLieuFormationConfirmTitle,
  DisableLieuFormationConfirmMessage,
  PasEmailPromotionnel,
  SuspensionsAgrementNonModifiables,
  EditSuspensions,
  AddSuspensions,
  BaremeTheorique,
  AfficherSupprimés,
  Exports,
  RapportsCentres,
  RapportsServices,
  Publipostage,
  IdrapportHopeType,
  Path,
  Page,
  PasDeDonneesAAfficher,
  Generer,
  FichierXmlAvecDonneesRapport,
  CSVComma,
  CSVSemiColon,
  CSVNoHeader,
  TABDelimited,
  PDF,
  MHTML,
  Excel,
  FichierTIFF,
  Word,
  PasDeParametres,
  LoadingProgress,
  Rapports,
  InvalidationDirection,
  DegreDifferentFromClasse,
  TotalPointsTJExam,
  PresenceApp,
  WithHoraire,
  DeleteHoraireConfirmationMessage,
  DeleteHoraireDialogTitle,
  ApprenantTrouve,
  AucunApprenantTrouve,
  AucunApprenantTrouvePourNiss,
  PlusieursApprenantsTrouves,
  DateEntreeParcours,
  ServerValidationError_SiegeIsInactif,
  ApprenantNameExistsSave,
  IdserviceTutelle,
  IdreferentIfapme,
  NomReferent,
  PrenomReferent,
  VoirOffre,
  AgrementSuspensionWarningTitle,
  AgrementSuspensionWarningMessage,
  AtLeastOneRepresentantField,
  StatutInscription_None,
  StatutInscription_Current,
  StatutInscription_Waiting,
  ContratsActifsEnCoursApprenant,
  Manuel,
  PriseEnMain,
  ActionCode_EXPORTS,
  ActionCode_PUBLIPOSTAGE,
  ActionCode_RAPPORTS,
  ActionCode_SIEGESOCIAL,
  ActionCode_SOCIETEEXTERNE,
  ActionCode_SSRSREPORTS,
  ActionCode_SUIVISIEGELIEU,
  ActionCode_PASSPORTDRIVE,
  ActionCode_PERSONNE,
  DownloadBeidNew,
  ConsentementCollecteDonnees,
  VoirConseeillerPedagogique,
  BackendError_CannotDeleteLfSuiviFilesLinked,
  NouveauStatutPostFormation,
  DateStatut,
  SuiviTermine,
  DashboardDossierType_StatutPostFormation,
  PostFormation,
  MasquerLigneConfirmTitle,
  MasquerLigneConfirmMessage,
  MasquerLigne,
  CategorieStatutApprenant_PostFormation,
  CategorieStatutApprenant_Entree,
  CategorieStatutApprenant_Sortie,
  CategorieStatutApprenant_SortieAnnulee,
  CategorieStatutApprenant_SortieEnCours,
  DateCannotBePastOf,
  Personne1,
  Personne2,
  DateNaissance1,
  DateNaissance2,
  ApprenantNb,
  NbInscriptions,
  NbDocuments,
  NbAbsences,
  NbSuivis,
  NbContrats,
  HomonymeConfirmTitle,
  HomonymeConfirmMessage,
  HomonymeError,
  MergeConfirmTitle,
  MergeConfirmMessage,
  FusionError,
  DoublonApprenantRepresentantCumules,
  VeuillezChoisirContactUrgence,
  ContactUrgenceApprenant,
  DoublonApprenantParcoursCumules,
  CategorieStatutSortie,
  StatutSortie,
  ParcoursActuelPlusRecentChoisi,
  PasDeParcoursEnCours,
  ParcoursFormationActuel,
  StatutActuel,
  VeuillezChoisirStatutActuel,
  DoublonApprenantStatutsCumules,
  PasDeStatutEnCours,
  PasDePassportDrive,
  DoublonApprenantPassportDriveAuto,
  DoublonsDossierScolairePlusRecentChoisi,
  DoublonApprenantDocumentsCumules,
  PersonneNb,
  NbAcquis,
  CompteBancaire,
  VoirPersonne,
  Telephones,
  ChefEntrepriseNb,
  DoublonChefEntrepriseSiegeSocialCumules,
  ContactNb,
  DoublonContactSiegeSocialCumules,
  DoublonContactLieuFormationCumules,
  TuteurNb,
  DoublonTuteurLieuFormationCumules,
  DoublonTuteurMetiersContratsCumules,
  RepresentantNb,
  FormateurNb,
  NbLocalisations,
  NbAgrements,
  NbDoublonRemplacements,
  Professions,
  CALCRSA,
  ServerValidationError_InvalidDatesCombination,
  ServerValidationError_CurrentCalcAlreadyExisting,
  RegistreNational1,
  RegistreNational2,
  NouveauDoublon,
  VeuillezDeselectionnerAutreLigne,
  Nom1,
  Nom2,
  Prenom1,
  SecondPrenom,
  Personne_Merge_Doublons,
  Apprenant_Merge_Doublons,
  Tuteur_Merge_Doublons,
  PRIApprenantSelectionne,
  PRIContratSelectionne,
  ARIApprenantSelectionne,
  ARIContratSelectionne,
  RoleNonAutorise,
  BackendError_UserNotAuthorizedForMerge,
  ApprenantSecurise,
  ApprenantNonSecurise,
  ApprenantSecuriseMessage,
  ApprenantSecuriseTitle,
  ActionCode_NEW,
  StatutContrat_undefined,
  AnneeDebut,
  AnneeFin,
  TitreDiplomeCertificationIfapmes,
  CannotDeleteRepresentant,
  ComptesBancaires,
  NoCompteBancaireAddedMessage,
  NoCompteBancaireAdded,
  BackendError_CannotDeleteRepresentant,
  BackendError_ClasseMatiereLinkedToEvaluations,
  ActionCode_AGREMENT_SECT2,
  AgrementLieuFormation,
  TypeCoursDifferentFromClasse,
  NouvelleInscription,
  SuspensionInscriptionSupprimee,
  InscriptionAlreadySuspended,
  InscriptionSoonSuspended,
  SuspensionInscription,
  MettreEntreAbandon,
  Terminer,
  InscriptionTermineeAvecSucces,
  AbandonInscription,
  AucuneInscriptionElligiblesAuxActionsMultiples,
  DateFinSuspension,
  Periode,
  VeuillezRemplirTousLesChamps,
  ChangementCentre,
  AncienneClasse,
  AvertirConvoquer,
  AvertirConvoquerConfirmTitle,
  AvertirConvoquerConfirmMessage,
  DashboardDossierType_FinAgrementProvisoire,
  Concerne,
  RemarqueRefusCentre,
  AtLeastOneField,
  OldCsBaremes,
  CurrentPri,
  BackendError_UserNotInCentres,
  BackendError_InscriptionVisibleBulletin,
  DateCannotBeBefore,
  AzureEmail,
  YouCantAccessThisApplication,
  BackendError_NoValidInscriptions,
  IdtypeContrat,
  EmailsRepresentants,
  EmailsContacts,
  Impersonate,
  AreYouSureYouWantToImpersonateThisUser,
  OnBehalfOf,
  RemplacantDecede,
  CloturerSuspension,
  SimpleSortieReseauConfirmMessage,
  InscriptionMasse_Degres,
  InscriptionMasse_Idsclasse,
  InscriptionMasse_Masculins,
  InscriptionMasse_Classes,
  InscriptionMasse_AnneesScolaires,
  InscriptionMasse_Deliberations,
  InscriptionMasse_Deliberations2sess,
  InscriptionMasse_CodesMetier,
  E8E9,
  TypeBulletin,
  Certification,
  PcMinReussite,
  UAA,
  VisibleBulletin,
  Sommative,
  TypesBulletin,
  CanPrintBulletin,
  GenererBulletins,
  SearchAtLeastAnneeAndCentre,
  BulletinGenerationWarningNb,
  BulletinGenerationWarningMetier,
  BulletinGenerationWarningQuestion,
  ErrorWhileCancellingPrinting,
  ErrorWhileDeletingPrinting,
  NoPrintingsToday,
  RapportWalter,
  RapportHope,
  ExportWalter,
  AddingBulletinToGenerationQueue,
  ErrorWhileAddingBulletinToQueue,
  BulletinsAddedToQueue,
  ErrorWhileDownloadingPrinting,
  TelechargerSelection,
  MatiereParente,
  VoirTout,
  DeletedByOn,
  RaisonVar,
  GenererAvisResultats1Sess,
  GenererAvisResultats2Sess,
  NoAvisToGenerate,
  GenerationIndisponibleAvant20232024,
  ValidateAvisResultats1Sess,
  ValidateAvisResultats2Sess,
  InvalidateAvisResultats,
  DownloadAvisResultats1S,
  DownloadAvisResultats2S,
  BackendError_AnneeScolaireMustBeBefore2024,
  CommissionParitaires,
  ActionCode_COMMISSIONPARITAIRE,
  DureeInoccupation,
  DonneeManquante,
  Apprenant_Create_SortieApprenant,
  Apprenant_Add_StatutApprenant,
  Apprenant_Update_StatutApprenant,
  Apprenant_Create_ParcoursFormation,
  AtLeastOneOfThoseField,
  BulletinGenerationWarningNbClasse,
  ErrorWhileShowingRow,
  RapportsImprimesAujourdhui,
  DeselectAll,
  AucunFichierATelecharger,
  ErrorWhileInvalidation,
  SelectionActions,
  MasquerSelection,
  SupprimerSelection,
  ErrorWhileMaskingprintings,
  ErrorWhileDeletingPrintings,
  MustWaitAfterGenerationBeforeInvalidating,
  AnnulerSelection,
  ErrorWhileCancellingPrintings,
  PrintingHistory,
  VoirHistorique,
  Hidden,
  NomRapport,
  BackendError_TypeBulletinMissingInClasse,
  DerniersAvisDeResultatsImprimes,
  AnnulerMasquerGenerationPrecedente,
  AnnulerMasquerGenerationPrecedenteMessage,
  BulletinHasEmptyEvaluations,
  AnErrorOccuredWhilePrintingAr,
  ManuelAvisResultats,
  FichierNonTrouve,
  NouvelleFonctionnalité,
  Acknowledged,
  ServerValidationError_DuplicateExist,
  ServerValidationError_CannotEditLinkedToApprenant,
  Prospects,
  Prospect,
  ParcoursCrea,
  ProjetEntreprise,
  NotorieteIFAPME,
  DatePrevisionnelleLancement,
  AmbassadeursCrea,
  DemandeFormationCrea,
  AddDemandeFormation,
  PremierSemestre,
  PremierSemestreWithDates,
  Refuse,
  ActionCode_DEMANDESCREA,
  ActionCode_PROSPECT,
  ActionCode_CREA,
  IsCrea,
  NumeroEntreprise,
  DateLancement,
  StatutCrea,
  StatutSpecifique,
  DateLancementGreaterThanDatePrevisionnelle,
  InvalidNumberEntreprise,
  DatePrevisonnelleGreaterThanDateLancement,
  SecteurCrea,
  ModifierStatut,
  HistoriqueChangementCrea,
  UtiliserCeStatutCommePrincipal,
  StatutPrincipal,
  ModulesCrea,
  ModuleCrea,
  ErrorWhileAddingReportToQueue,
  BackendError_ReportNotFound,
  ReportAddedToQueue,
  AddingReportToGenerationQueue,
  BackendError_ReportAlreadyPrinting,
  DerniersRapportsImprimes,
  DownloadingWillDeleteFile,
  FichierDejaTelecharge,
  Hide,
  Show,
  StatutPrinting_GENERATING,
  StatutPrinting_DONE,
  StatutPrinting_ERROR,
  StatutPrinting_CANCELLED,
  StatutPrinting_WAITING,
  IdapprenantAr,
  ManuelGenerationRapport,
  SecondSemestre,
  PorteursDeProjets,
  CannotPasteOutOfClass,
  Smile
}
