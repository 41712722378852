import { FGEmpty, FGTextInput, FieldGroup, FGNumberInput, FGMaskInput } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { PlageHoraireApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { Divider } from "@blueprintjs/core";
import { useGlobalData } from "../../../../../contexts";

export interface ICentreHoraireDetailsProps {}

export const CentreHoraireDetails: React.FunctionComponent<ICentreHoraireDetailsProps> = () => {
  const { t } = useTl();
  const { tUnsafe } = useTl();
  const api = useApiService(PlageHoraireApi);
  const history = useHistory();
  const globalData = useGlobalData();
  const { id, plageId, state } = useParams<{ id: string; plageId: string; state: string }>();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => api.plageHoraireGet({ id: +plageId, nbPlages: globalData.nbPlages, idCentre: +id }),
    saveApiFn: d => api.plageHoraireSave({ FcbPlageHoraireDto: d }),
    onSavedRoute: d => `${ERoutes.centre}/${d.idcentre}/horaire/${d.idplage}/edit`,
    deleteApiFn: d => api.plageHoraireDelete({ id: d.idplage }),
    onDeletedRoute: () => `${ERoutes.centre}/${id}/horaire/`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      duree: Yup.string().required(t(ETLCodes.Required)),
      description: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={state === "edit"}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(`${ERoutes.centre}/${id}/horaire/`)}
        onDelete={deleteItem}
        showDeleteButton={+plageId > 0}
        deleteBtnDisabled={data?.usedInClasse}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup>
          <FGNumberInput
            name="duree"
            label={t(ETLCodes.Duree)}
            labelInfo={t(ETLCodes.Minutes)}
            readonly={data?.usedInClasse}
          />
          <FGEmpty />
          <FGTextInput
            name="description"
            maxLength={20}
            label={t(ETLCodes.Description)}
            readonly={data?.usedInClasse}
          />
          <Divider />
          <FieldGroup columns={3}>
            {data.plagesDetails.map((value, index) => (
              <FGMaskInput
                name={`plagesDetails[${index}].heureDebut`}
                label={tUnsafe("Plage" + value.numero)}
                cleaveOptions={{ time: true, timePattern: ["h", "m"] }}
                readonly={data?.usedInClasse && !!value?.heureDebut}
              />
            ))}
          </FieldGroup>
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
