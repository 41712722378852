export const versions = {
  version: "1.0.183",
  revision: "bd2997099",
  branch: "HEAD",
  gitInfo: {
    dirty: true,
    raw: "v1.0.183-4714-gbd2997099-dirty",
    hash: "gbd2997099",
    distance: 4714,
    tag: "v1.0.183",
    semver: {
      options: { loose: false, includePrerelease: false },
      loose: false,
      raw: "v1.0.183",
      major: 1,
      minor: 0,
      patch: 183,
      prerelease: [],
      build: [],
      version: "1.0.183"
    },
    suffix: "4714-gbd2997099-dirty",
    semverString: "1.0.183+4714.gbd2997099.dirty"
  }
};
