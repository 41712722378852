/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface SuiviApprenantFichierUploadDto
 */
export interface SuiviApprenantFichierUploadDto {
    /**
     * 
     * @type {FileUploadDto}
     * @memberof SuiviApprenantFichierUploadDto
     */
    file?: FileUploadDto;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantFichierUploadDto
     */
    idsuiviApprenantFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantFichierUploadDto
     */
    idsuiviApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantFichierUploadDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantFichierUploadDto
     */
    idtypeSuiviFichier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviApprenantFichierUploadDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the SuiviApprenantFichierUploadDto interface.
 */
export function instanceOfSuiviApprenantFichierUploadDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviApprenantFichierUploadDtoFromJSON(json: any): SuiviApprenantFichierUploadDto {
    return SuiviApprenantFichierUploadDtoFromJSONTyped(json, false);
}

export function SuiviApprenantFichierUploadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviApprenantFichierUploadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': !exists(json, 'file') ? undefined : FileUploadDtoFromJSON(json['file']),
        'idsuiviApprenantFichier': !exists(json, 'idsuiviApprenantFichier') ? undefined : json['idsuiviApprenantFichier'],
        'idsuiviApprenant': !exists(json, 'idsuiviApprenant') ? undefined : json['idsuiviApprenant'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeSuiviFichier': !exists(json, 'idtypeSuiviFichier') ? undefined : json['idtypeSuiviFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function SuiviApprenantFichierUploadDtoToJSON(value?: SuiviApprenantFichierUploadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': FileUploadDtoToJSON(value.file),
        'idsuiviApprenantFichier': value.idsuiviApprenantFichier,
        'idsuiviApprenant': value.idsuiviApprenant,
        'idfichier': value.idfichier,
        'idtypeSuiviFichier': value.idtypeSuiviFichier,
        'fileName': value.fileName,
    };
}

