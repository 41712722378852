/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseDetailDto
 */
export interface FcbClasseDetailDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    heureReferentiel?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    statutAgrement?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    horaireModificationUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    horaireModificationDate?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbClasseDetailDto
     */
    idsSocieteExterne?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    hasPresences?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    hasHoraires?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    hasRemplacements?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    particulariteCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    iddegre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idcentre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idmetier?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idlieu?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idsecteur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    genre?: string;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    dateCours?: Date;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    horaireCoherent?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    agrement?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursLundi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursLundiFin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursMardi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursMardiFin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursMercredi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursMercrediFin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursJeudi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursJeudiFin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursVendredi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursVendrediFin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursSamedi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursSamediFin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursDimanche?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coursDimancheFin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idcalendrier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idreferentiel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idplage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idlocalJour1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idlocalJour2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idlocalJour3?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idlocalJour4?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idlocalJour5?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idlocalJour6?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idlocalJour7?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    financementType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    financementProjet?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    nbheuresSemestre1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    nbheuresSemestre2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    fraisPoe?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    resultatLock?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    pcReussite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idtypeReussite?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    dateAgrement?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    codeclasse?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    examenc?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    fictif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    bloque?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    debloqueUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    debloqueDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idplanLocalisation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idstatutLocalisation?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    dateLocalisation?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    nbapprenantLocalisation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idstatutAgrement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    nbapprenantAgrement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idplanAgrement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    typeCoursLocalisation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    particulariteCoursLocalisation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    idgenreClasseLocalisation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idmetierLocalisation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    iddegreLocalisation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    remarqueCentre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    remarqueConseiller?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    gestionInterne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    idconseillerLocalisation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    idconseillerAgrement?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    dateValidation?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    bloqueLocalisation?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    bloqueAgrement?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    uniteActivite?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    principale?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    deliberee?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    coutTotal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    remarqueCentreAgrement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    remarqueConseillerAgrement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    gestionInterneAgrement?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    bloqueNbreApprenantAgrement?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseDetailDto
     */
    codeClasseGap?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    nbapprenantAgrementCalculated?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    horaireModificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseDetailDto
     */
    lastModificationDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseDetailDto
     */
    e8E9?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idtypeBulletin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseDetailDto
     */
    idcertification?: number | null;
}

/**
 * Check if a given object implements the FcbClasseDetailDto interface.
 */
export function instanceOfFcbClasseDetailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseDetailDtoFromJSON(json: any): FcbClasseDetailDto {
    return FcbClasseDetailDtoFromJSONTyped(json, false);
}

export function FcbClasseDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'heureReferentiel': !exists(json, 'heureReferentiel') ? undefined : json['heureReferentiel'],
        'statutAgrement': !exists(json, 'statutAgrement') ? undefined : json['statutAgrement'],
        'horaireModificationUserName': !exists(json, 'horaireModificationUserName') ? undefined : json['horaireModificationUserName'],
        'horaireModificationDate': !exists(json, 'horaireModificationDate') ? undefined : json['horaireModificationDate'],
        'idsSocieteExterne': !exists(json, 'idsSocieteExterne') ? undefined : json['idsSocieteExterne'],
        'hasPresences': !exists(json, 'hasPresences') ? undefined : json['hasPresences'],
        'hasHoraires': !exists(json, 'hasHoraires') ? undefined : json['hasHoraires'],
        'hasRemplacements': !exists(json, 'hasRemplacements') ? undefined : json['hasRemplacements'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'particulariteCours': !exists(json, 'particulariteCours') ? undefined : json['particulariteCours'],
        'iddegre': !exists(json, 'iddegre') ? undefined : json['iddegre'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idlieu': !exists(json, 'idlieu') ? undefined : json['idlieu'],
        'idsecteur': !exists(json, 'idsecteur') ? undefined : json['idsecteur'],
        'genre': !exists(json, 'genre') ? undefined : json['genre'],
        'dateCours': !exists(json, 'dateCours') ? undefined : (new Date(json['dateCours'])),
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'horaireCoherent': !exists(json, 'horaireCoherent') ? undefined : json['horaireCoherent'],
        'agrement': !exists(json, 'agrement') ? undefined : json['agrement'],
        'coursLundi': !exists(json, 'coursLundi') ? undefined : json['coursLundi'],
        'coursLundiFin': !exists(json, 'coursLundiFin') ? undefined : json['coursLundiFin'],
        'coursMardi': !exists(json, 'coursMardi') ? undefined : json['coursMardi'],
        'coursMardiFin': !exists(json, 'coursMardiFin') ? undefined : json['coursMardiFin'],
        'coursMercredi': !exists(json, 'coursMercredi') ? undefined : json['coursMercredi'],
        'coursMercrediFin': !exists(json, 'coursMercrediFin') ? undefined : json['coursMercrediFin'],
        'coursJeudi': !exists(json, 'coursJeudi') ? undefined : json['coursJeudi'],
        'coursJeudiFin': !exists(json, 'coursJeudiFin') ? undefined : json['coursJeudiFin'],
        'coursVendredi': !exists(json, 'coursVendredi') ? undefined : json['coursVendredi'],
        'coursVendrediFin': !exists(json, 'coursVendrediFin') ? undefined : json['coursVendrediFin'],
        'coursSamedi': !exists(json, 'coursSamedi') ? undefined : json['coursSamedi'],
        'coursSamediFin': !exists(json, 'coursSamediFin') ? undefined : json['coursSamediFin'],
        'coursDimanche': !exists(json, 'coursDimanche') ? undefined : json['coursDimanche'],
        'coursDimancheFin': !exists(json, 'coursDimancheFin') ? undefined : json['coursDimancheFin'],
        'idcalendrier': !exists(json, 'idcalendrier') ? undefined : json['idcalendrier'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'idplage': !exists(json, 'idplage') ? undefined : json['idplage'],
        'idlocalJour1': !exists(json, 'idlocalJour1') ? undefined : json['idlocalJour1'],
        'idlocalJour2': !exists(json, 'idlocalJour2') ? undefined : json['idlocalJour2'],
        'idlocalJour3': !exists(json, 'idlocalJour3') ? undefined : json['idlocalJour3'],
        'idlocalJour4': !exists(json, 'idlocalJour4') ? undefined : json['idlocalJour4'],
        'idlocalJour5': !exists(json, 'idlocalJour5') ? undefined : json['idlocalJour5'],
        'idlocalJour6': !exists(json, 'idlocalJour6') ? undefined : json['idlocalJour6'],
        'idlocalJour7': !exists(json, 'idlocalJour7') ? undefined : json['idlocalJour7'],
        'financementType': !exists(json, 'financementType') ? undefined : json['financementType'],
        'financementProjet': !exists(json, 'financementProjet') ? undefined : json['financementProjet'],
        'nbheuresSemestre1': !exists(json, 'nbheuresSemestre1') ? undefined : json['nbheuresSemestre1'],
        'nbheuresSemestre2': !exists(json, 'nbheuresSemestre2') ? undefined : json['nbheuresSemestre2'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'fraisPoe': !exists(json, 'fraisPoe') ? undefined : json['fraisPoe'],
        'resultatLock': !exists(json, 'resultatLock') ? undefined : json['resultatLock'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'pcReussite': !exists(json, 'pcReussite') ? undefined : json['pcReussite'],
        'idtypeReussite': !exists(json, 'idtypeReussite') ? undefined : json['idtypeReussite'],
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateAgrement': !exists(json, 'dateAgrement') ? undefined : (json['dateAgrement'] === null ? null : new Date(json['dateAgrement'])),
        'codeclasse': !exists(json, 'codeclasse') ? undefined : json['codeclasse'],
        'examenc': !exists(json, 'examenc') ? undefined : json['examenc'],
        'fictif': !exists(json, 'fictif') ? undefined : json['fictif'],
        'bloque': !exists(json, 'bloque') ? undefined : json['bloque'],
        'debloqueUser': !exists(json, 'debloqueUser') ? undefined : json['debloqueUser'],
        'debloqueDate': !exists(json, 'debloqueDate') ? undefined : (json['debloqueDate'] === null ? null : new Date(json['debloqueDate'])),
        'idplanLocalisation': !exists(json, 'idplanLocalisation') ? undefined : json['idplanLocalisation'],
        'idstatutLocalisation': !exists(json, 'idstatutLocalisation') ? undefined : json['idstatutLocalisation'],
        'dateLocalisation': !exists(json, 'dateLocalisation') ? undefined : (json['dateLocalisation'] === null ? null : new Date(json['dateLocalisation'])),
        'nbapprenantLocalisation': !exists(json, 'nbapprenantLocalisation') ? undefined : json['nbapprenantLocalisation'],
        'idstatutAgrement': !exists(json, 'idstatutAgrement') ? undefined : json['idstatutAgrement'],
        'nbapprenantAgrement': !exists(json, 'nbapprenantAgrement') ? undefined : json['nbapprenantAgrement'],
        'idplanAgrement': !exists(json, 'idplanAgrement') ? undefined : json['idplanAgrement'],
        'typeCoursLocalisation': !exists(json, 'typeCoursLocalisation') ? undefined : json['typeCoursLocalisation'],
        'particulariteCoursLocalisation': !exists(json, 'particulariteCoursLocalisation') ? undefined : json['particulariteCoursLocalisation'],
        'idgenreClasseLocalisation': !exists(json, 'idgenreClasseLocalisation') ? undefined : json['idgenreClasseLocalisation'],
        'idmetierLocalisation': !exists(json, 'idmetierLocalisation') ? undefined : json['idmetierLocalisation'],
        'iddegreLocalisation': !exists(json, 'iddegreLocalisation') ? undefined : json['iddegreLocalisation'],
        'remarqueCentre': !exists(json, 'remarqueCentre') ? undefined : json['remarqueCentre'],
        'remarqueConseiller': !exists(json, 'remarqueConseiller') ? undefined : json['remarqueConseiller'],
        'gestionInterne': !exists(json, 'gestionInterne') ? undefined : json['gestionInterne'],
        'idconseillerLocalisation': !exists(json, 'idconseillerLocalisation') ? undefined : json['idconseillerLocalisation'],
        'idconseillerAgrement': !exists(json, 'idconseillerAgrement') ? undefined : json['idconseillerAgrement'],
        'dateValidation': !exists(json, 'dateValidation') ? undefined : (json['dateValidation'] === null ? null : new Date(json['dateValidation'])),
        'bloqueLocalisation': !exists(json, 'bloqueLocalisation') ? undefined : json['bloqueLocalisation'],
        'bloqueAgrement': !exists(json, 'bloqueAgrement') ? undefined : json['bloqueAgrement'],
        'uniteActivite': !exists(json, 'uniteActivite') ? undefined : json['uniteActivite'],
        'principale': !exists(json, 'principale') ? undefined : json['principale'],
        'deliberee': !exists(json, 'deliberee') ? undefined : json['deliberee'],
        'coutTotal': !exists(json, 'coutTotal') ? undefined : json['coutTotal'],
        'remarqueCentreAgrement': !exists(json, 'remarqueCentreAgrement') ? undefined : json['remarqueCentreAgrement'],
        'remarqueConseillerAgrement': !exists(json, 'remarqueConseillerAgrement') ? undefined : json['remarqueConseillerAgrement'],
        'gestionInterneAgrement': !exists(json, 'gestionInterneAgrement') ? undefined : json['gestionInterneAgrement'],
        'bloqueNbreApprenantAgrement': !exists(json, 'bloqueNbreApprenantAgrement') ? undefined : json['bloqueNbreApprenantAgrement'],
        'codeClasseGap': !exists(json, 'codeClasseGap') ? undefined : json['codeClasseGap'],
        'nbapprenantAgrementCalculated': !exists(json, 'nbapprenantAgrementCalculated') ? undefined : json['nbapprenantAgrementCalculated'],
        'horaireModificationUser': !exists(json, 'horaireModificationUser') ? undefined : json['horaireModificationUser'],
        'lastModificationDate': !exists(json, 'lastModificationDate') ? undefined : (json['lastModificationDate'] === null ? null : new Date(json['lastModificationDate'])),
        'e8E9': !exists(json, 'e8E9') ? undefined : json['e8E9'],
        'idtypeBulletin': !exists(json, 'idtypeBulletin') ? undefined : json['idtypeBulletin'],
        'idcertification': !exists(json, 'idcertification') ? undefined : json['idcertification'],
    };
}

export function FcbClasseDetailDtoToJSON(value?: FcbClasseDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'heureReferentiel': value.heureReferentiel,
        'statutAgrement': value.statutAgrement,
        'horaireModificationUserName': value.horaireModificationUserName,
        'horaireModificationDate': value.horaireModificationDate,
        'idsSocieteExterne': value.idsSocieteExterne,
        'hasPresences': value.hasPresences,
        'hasHoraires': value.hasHoraires,
        'hasRemplacements': value.hasRemplacements,
        'idclasse': value.idclasse,
        'particulariteCours': value.particulariteCours,
        'iddegre': value.iddegre,
        'idcentre': value.idcentre,
        'idmetier': value.idmetier,
        'idlieu': value.idlieu,
        'idsecteur': value.idsecteur,
        'genre': value.genre,
        'dateCours': value.dateCours === undefined ? undefined : (value.dateCours.toISOString()),
        'anneeAcademique': value.anneeAcademique,
        'nom': value.nom,
        'remarque': value.remarque,
        'horaireCoherent': value.horaireCoherent,
        'agrement': value.agrement,
        'coursLundi': value.coursLundi,
        'coursLundiFin': value.coursLundiFin,
        'coursMardi': value.coursMardi,
        'coursMardiFin': value.coursMardiFin,
        'coursMercredi': value.coursMercredi,
        'coursMercrediFin': value.coursMercrediFin,
        'coursJeudi': value.coursJeudi,
        'coursJeudiFin': value.coursJeudiFin,
        'coursVendredi': value.coursVendredi,
        'coursVendrediFin': value.coursVendrediFin,
        'coursSamedi': value.coursSamedi,
        'coursSamediFin': value.coursSamediFin,
        'coursDimanche': value.coursDimanche,
        'coursDimancheFin': value.coursDimancheFin,
        'idcalendrier': value.idcalendrier,
        'idreferentiel': value.idreferentiel,
        'idplage': value.idplage,
        'idlocalJour1': value.idlocalJour1,
        'idlocalJour2': value.idlocalJour2,
        'idlocalJour3': value.idlocalJour3,
        'idlocalJour4': value.idlocalJour4,
        'idlocalJour5': value.idlocalJour5,
        'idlocalJour6': value.idlocalJour6,
        'idlocalJour7': value.idlocalJour7,
        'financementType': value.financementType,
        'financementProjet': value.financementProjet,
        'nbheuresSemestre1': value.nbheuresSemestre1,
        'nbheuresSemestre2': value.nbheuresSemestre2,
        'typeCours': value.typeCours,
        'fraisPoe': value.fraisPoe,
        'resultatLock': value.resultatLock,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'pcReussite': value.pcReussite,
        'idtypeReussite': value.idtypeReussite,
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateAgrement': value.dateAgrement === undefined ? undefined : (value.dateAgrement === null ? null : value.dateAgrement.toISOString()),
        'codeclasse': value.codeclasse,
        'examenc': value.examenc,
        'fictif': value.fictif,
        'bloque': value.bloque,
        'debloqueUser': value.debloqueUser,
        'debloqueDate': value.debloqueDate === undefined ? undefined : (value.debloqueDate === null ? null : value.debloqueDate.toISOString()),
        'idplanLocalisation': value.idplanLocalisation,
        'idstatutLocalisation': value.idstatutLocalisation,
        'dateLocalisation': value.dateLocalisation === undefined ? undefined : (value.dateLocalisation === null ? null : value.dateLocalisation.toISOString()),
        'nbapprenantLocalisation': value.nbapprenantLocalisation,
        'idstatutAgrement': value.idstatutAgrement,
        'nbapprenantAgrement': value.nbapprenantAgrement,
        'idplanAgrement': value.idplanAgrement,
        'typeCoursLocalisation': value.typeCoursLocalisation,
        'particulariteCoursLocalisation': value.particulariteCoursLocalisation,
        'idgenreClasseLocalisation': value.idgenreClasseLocalisation,
        'idmetierLocalisation': value.idmetierLocalisation,
        'iddegreLocalisation': value.iddegreLocalisation,
        'remarqueCentre': value.remarqueCentre,
        'remarqueConseiller': value.remarqueConseiller,
        'gestionInterne': value.gestionInterne,
        'idconseillerLocalisation': value.idconseillerLocalisation,
        'idconseillerAgrement': value.idconseillerAgrement,
        'dateValidation': value.dateValidation === undefined ? undefined : (value.dateValidation === null ? null : value.dateValidation.toISOString()),
        'bloqueLocalisation': value.bloqueLocalisation,
        'bloqueAgrement': value.bloqueAgrement,
        'uniteActivite': value.uniteActivite,
        'principale': value.principale,
        'deliberee': value.deliberee,
        'coutTotal': value.coutTotal,
        'remarqueCentreAgrement': value.remarqueCentreAgrement,
        'remarqueConseillerAgrement': value.remarqueConseillerAgrement,
        'gestionInterneAgrement': value.gestionInterneAgrement,
        'bloqueNbreApprenantAgrement': value.bloqueNbreApprenantAgrement,
        'codeClasseGap': value.codeClasseGap,
        'nbapprenantAgrementCalculated': value.nbapprenantAgrementCalculated,
        'horaireModificationUser': value.horaireModificationUser,
        'lastModificationDate': value.lastModificationDate === undefined ? undefined : (value.lastModificationDate === null ? null : value.lastModificationDate.toISOString()),
        'e8E9': value.e8E9,
        'idtypeBulletin': value.idtypeBulletin,
        'idcertification': value.idcertification,
    };
}

