import { Colors, Icon, Intent, Tooltip } from "@blueprintjs/core";
import { format, isAfter, startOfDay } from "date-fns";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled, { css } from "styled-components";

import { EOrigineSuivi, SuiviApi, SuiviGridDto, SuiviSearchDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, CustomBulletList, EditButton, LinkButton, ViewButton } from "../../../../../components";
import { useAuth } from "../../../../../contexts";
import { useApiService, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface SuiviApprenantSearchPageProps {
  baseRoute: string;
}

export const SuiviApprenantSearchPage: React.FunctionComponent<SuiviApprenantSearchPageProps> = ({ baseRoute }) => {
  const { t } = useTl();
  const { user } = useAuth();
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const { theme, ifapmeSide } = useTheme();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(SuiviApi);
  const path = React.useMemo(() => `${baseRoute}/${apprenantId}/${tab}`, [apprenantId, baseRoute, tab]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateSuivi: "DESC", encodeur: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunc = React.useCallback(
    (sObj?: SuiviSearchDto) => {
      sObj.idapprenant = apprenantId;
      return api.suiviSearchSuiviHopeByApprenant({ SuiviSearchDto: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const getActionValue = React.useCallback((row: SuiviGridDto) => {
    if (!row.actionDateRealisation && !row.actionDateEcheance) return null;
    return format(!!row.actionDateRealisation ? row.actionDateRealisation : row.actionDateEcheance, "dd-MM-yyyy");
  }, []);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SuiviGridDto) => (
          <ButtonContainer>
            {row.idrealisateurSuivi === user.iduser && (
              <EditButton
                minimal={true}
                onClick={() =>
                  history.push(
                    `${path}/${row.idsuivi}/edit/${row.origineSuivi === EOrigineSuivi.Centres ? "walter" : "hope"}`
                  )
                }
              />
            )}
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(
                  `${path}/${row.idsuivi}/view/${row.origineSuivi === EOrigineSuivi.Centres ? "walter" : "hope"}`
                )
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Source),
        fieldName: "source",
        render: (row: SuiviGridDto) =>
          row.origineSuivi === EOrigineSuivi.Services
            ? row.service
            : row.origineSuivi === EOrigineSuivi.Centres
            ? row.centre
            : t(ETLCodes.Smile)
      },
      {
        header: () => t(ETLCodes.DateSuivi),
        fieldName: "dateSuivi"
      },
      {
        header: () => t(ETLCodes.RealisateurSuivi),
        fieldName: "realisateurSuivi",
        render: (row: SuiviGridDto) => (
          <LinkButton
            text={row.realisateurSuivi}
            onClick={() => window.open(`#${ERoutes.users}/${row.idrealisateurSuivi}/detail/view`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Encodeur),
        fieldName: "encodeur",
        render: (row: SuiviGridDto) => (
          <LinkButton
            text={row.encodeur}
            onClick={() => window.open(`#${ERoutes.users}/${row.idencodeur}/detail/view`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.TypeSuivi),
        fieldName: "typeSuivi"
      },
      {
        header: () => t(ETLCodes.SousTypeSuivi),
        fieldName: "sousTypeSuivi"
      },
      {
        header: () => t(ETLCodes.ContenuCache),
        fieldName: "hiddenContent"
      },
      {
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.ActionARealiser)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Legende)}
                  items={[
                    {
                      text: t(ETLCodes.ActionRealisee),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.DateEcheanceDepassee),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.ActionNonRealisee),
                      color: theme.warningColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "actionARealiser",
        render: (row: SuiviGridDto) =>
          (!!row.actionDateRealisation || row.actionDateEcheance) && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: !!row.actionDateRealisation
                  ? theme.sucessColor
                  : isAfter(startOfDay(new Date()), startOfDay(row.actionDateEcheance))
                  ? theme.dangerColor
                  : theme.warningColor
              }}
            >
              <Icon
                style={{ marginRight: ".5rem" }}
                icon={
                  !!row.actionDateRealisation
                    ? "tick"
                    : isAfter(startOfDay(new Date()), startOfDay(row.actionDateEcheance))
                    ? "issue"
                    : "time"
                }
              />
              {getActionValue(row)}
            </span>
          )
      }
    ],
    [getActionValue, history, path, t, theme.dangerColor, theme.sucessColor, theme.warningColor, user.iduser]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${path}/0/edit/${baseRoute !== ERoutes.prospect ? ifapmeSide : ""}`);
  }, [baseRoute, history, ifapmeSide, path]);

  return (
    <>
      {apprenantId > 0 && (
        <FieldSet
          title={t(ETLCodes.TableResults, { count: totalCount })}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            customizeRowStyle={(row: SuiviGridDto) => css`
              & * {
                color: ${row.fromCrea ? Colors.GREEN3 + " !important" : null};
              }
            `}
          />
        </FieldSet>
      )}
    </>
  );
};
