import "jspreadsheet-ce/dist/jspreadsheet.css";
import "jsuites/dist/jsuites.css";

import * as React from "react";
import jspreadsheet from "jspreadsheet-ce";

import { IJExcelProps, JExcelOptions } from "..";
import { Changes } from "./JExcelInterfaces";
import { colName } from "./JExcelUtils";

export function JExcel<T>(props: React.PropsWithChildren<IJExcelProps<T>>) {
  const {
    columns = [],
    data = [],
    allowInsertColumn = false,
    allowInsertRow = false,
    allowDeleteRow = false,
    allowDeleteColumn = false,
    columnDrag = false,
    nestedHeaders = [],
    rowResize = false,
    freezeColumns = 0,
    tableOverflow = false,
    filters = false,
    pagination = null,
    updateTable = () => {},
    onChange = () => {},
    pasteCheck,
    tableHeight,
    tableWidth,
    setJexcelInstance: setJexcelInstanceFromProps
  } = props;

  const [change, setChange] = React.useState<Changes>(null);

  const mapDataToColumnsData = React.useCallback(
    (data: Array<any>) => {
      return data.map(d => {
        const fields = columns.map(c => c.name);
        let mapped: any = {};
        fields.forEach(f => (mapped[f] = d[f]));
        return mapped;
      });
    },
    [columns]
  );

  const style = React.useMemo(() => {
    const mappedStyle: any = {};

    data.forEach((d, i) =>
      columns.forEach((c, j) => {
        if (c.dynamicStyle && c.dynamicStyle(d)) {
          mappedStyle[`${colName(j)}${i + 1}`] = c.dynamicStyle(d);
        }
      })
    );

    return mappedStyle;
  }, [columns, data]);

  const [jexcelInstance, setJexcelInstance] = React.useState<any>(null);

  const onChangeCallback = React.useCallback((instance: any, cell: any, x: any, y: any, value: any) => {
    setChange({ row: x, col: y, value });
  }, []);

  const onBeforePasteCallback = React.useCallback(
    (instance: any, value: any, x: any, y: any) => {
      return !!pasteCheck ? pasteCheck({ row: x, col: y, value }) : value;
    },
    [pasteCheck]
  );

  React.useEffect(() => {
    if (jexcelInstance) {
      setJexcelInstanceFromProps(jexcelInstance);
    }
  }, [jexcelInstance, setJexcelInstanceFromProps]);

  const options = React.useMemo<JExcelOptions>(() => {
    return {
      columns: columns.map(c => {
        return {
          ...c,
          source: c.source?.map(s => {
            return { id: s.value, name: s.label };
          })
        };
      }),
      data: mapDataToColumnsData(data),
      style,
      allowInsertColumn,
      allowInsertRow,
      columnDrag,
      nestedHeaders,
      rowResize,
      freezeColumns,
      tableOverflow,
      tableHeight,
      tableWidth,
      allowDeleteRow,
      allowDeleteColumn,
      filters,
      pagination,
      onchange: onChangeCallback,
      onbeforepaste: onBeforePasteCallback,
      updateTable
    };
  }, [
    columns,
    mapDataToColumnsData,
    data,
    style,
    allowInsertColumn,
    allowInsertRow,
    columnDrag,
    nestedHeaders,
    rowResize,
    freezeColumns,
    tableOverflow,
    tableHeight,
    tableWidth,
    allowDeleteRow,
    allowDeleteColumn,
    filters,
    pagination,
    onChangeCallback,
    onBeforePasteCallback,
    updateTable
  ]);

  const divRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!jexcelInstance) {
      const instance = jspreadsheet(divRef.current, options as any);
      setJexcelInstance(instance);
      return () => {
        //dispose of instance
        //instance
      };
    }
  }, [options, jexcelInstance]);

  React.useEffect(() => {
    if (jexcelInstance && change) {
      onChange(change);
    }
  }, [change, jexcelInstance, onChange]);

  return <div ref={divRef} style={{ width: "100%" }} />;
}
